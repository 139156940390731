import { Col, Row, Spin, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import SpinDiv from "../../components/common/spin-div";
import { DateRangePickerValues } from "../../components/controls/app-date-range-picker";
import AppDateRangePicker2 from "../../components/controls/app-date-range-picker-2";
import AppMobileDateRangePicker from "../../components/controls/app-mobile-date-range-picker";
import SalesAnalyticsCharts from "../../components/views/sales-analytics/charts";
import { DATE_TIME_FORMAT, DateRangeList } from "../../constants/app-constants";
import { DateRangeValues, SyncStatus, ZohoSyncStatus } from "../../constants/app.enums";
import useClient from "../../hooks/useClient";
import useZoho from "../../hooks/useZoho";
import { isMobileSelector } from "../../states/common";
import {
  saleAnalyticsDateRangeState,
  saleAnalyticZohoState,
} from "../../states/zoho";
import "./index.scss";
import SVGIcons from "../../components/icons/svgs";
import { ZohoApi } from "../../apis/zoho.api";

function SalesAnalytics() {
  const { t } = useTranslation();
  const isMobile = useRecoilValue(isMobileSelector);

  const [zohoState, setZohoState] = useRecoilState(saleAnalyticZohoState);
  const [syncStatus, setSyncStatus] = useState<SyncStatus>()

  const setDateRange = useSetRecoilState(saleAnalyticsDateRangeState);
  const [selectedRangeName, setSelectedRangeName] = useState<string>(
    t("common.thisWeek")
  );

  const {
    usersInfoLoading,
    reportsLoading,
    getUsersInfo,
    fetchAllReports,
    isAllChartsEmpty,
    isFetched,
  } = useZoho();

  const { client } = useClient();

  function dateChange(values: DateRangePickerValues) {
    const totalDays = values.endDate.diff(values.startDate, "days") + 1;
    const startDate = values.startDate.format(DATE_TIME_FORMAT.isoDateStartOfDay);
    const endDate = values.endDate.format(DATE_TIME_FORMAT.isoDateEndOfDay);

    const rangeName = DateRangeList.find(
      (x) => x.key === values.value
    )?.displayText;
    setSelectedRangeName(rangeName || t("common.thisWeek"));

    setDateRange({
      totalDays: totalDays,
      endDate: endDate,
      startDate: startDate,
      value: values.value,
    });
    setZohoState({
      ...zohoState,
      triggerReloadReports: zohoState.triggerReloadReports + 1,
    });
  }

  const onSyncData = async () => {
    if (!client?.id) return;
    try {
      setSyncStatus(SyncStatus.Syncing);
      await ZohoApi.syncLatest(client?.id);
      await checkPendingSyncJobs();
    } catch (error) {
      setSyncStatus(SyncStatus.None);
    }
  };

  const renderSyncStatus = () => {
    switch (syncStatus) {
      case SyncStatus.Syncing:
        return <><div className="icon-refresh syncing spin-animation" ><SVGIcons.RefreshIcon /></div>{t("salesAnalytics.syncing")}</>;

      case SyncStatus.Synced:
        return <><SVGIcons.SyncDoneIcon />{t("salesAnalytics.synced")}</>;

      default:
        return "";
    }
  }

  const checkPendingSyncJobs = () => {
    let attempts = 0;
    let intervalId: NodeJS.Timeout;
    const MAX_ATTEMPTS = 10;
    const DELAY = 15000;
    if (!client?.id) return;

    const request = async () => {
      attempts++;
      try {

        const { data } = await ZohoApi.getPendingSyncJobs(client?.id);



        const allJobsSuccess = data.jobs.every(x => x.status === ZohoSyncStatus.SUCCESS);

        if (allJobsSuccess) {
          clearInterval(intervalId);

          setZohoState({
            ...zohoState,
            triggerReloadReports: zohoState.triggerReloadReports + 1,
          });
          setSyncStatus(SyncStatus.Synced);
          return data;
        }

        if (
          data.jobs.filter(x => x.status === ZohoSyncStatus.NEW).length > 0 ||
          data.jobs.filter(x => x.status === ZohoSyncStatus.RUNNING).length > 0
        ) {
          setSyncStatus(SyncStatus.Syncing);
          if (attempts >= MAX_ATTEMPTS) {
            clearInterval(intervalId);
            setSyncStatus(SyncStatus.None);
            return data;
          }
        }
      } catch (error) {
        clearInterval(intervalId);
        setSyncStatus(SyncStatus.None);
        throw error;
      }
    };

    const promise = request();

    intervalId = setInterval(request, DELAY);

    return promise;
  };

  useEffect(() => {
    setZohoState({
      ...zohoState,
      triggerReloadReports: 0,
    });
    if (zohoState.triggerReloadReports > 0) {
      fetchAllReports();
    }
  }, [zohoState.triggerReloadReports]);

  useEffect(() => {
    getUsersInfo();
  }, [client?.id]);


  return (
    <div className="sales-analytics page-content">
      <div className="page-header">
        <Row>
          <Col xs={18} sm={12}>
            <div className="page-header-with-icon">
              <h4 className="page-header__title">
                {t("common.salesAnalytics")}
              </h4>
              {syncStatus === SyncStatus.None ? (
                <div onClick={onSyncData} className="icon-refresh">
                  <SVGIcons.RefreshIcon />
                </div>
              ) : (
                <>
                  {
                    syncStatus ? <div className="sync-status" >
                      {renderSyncStatus()}
                    </div> : <Tooltip
                      overlayClassName="custom-tooltip"
                      title={<span>{t("salesAnalytics.refreshLatestData")}</span>}
                    >
                      <div onClick={onSyncData} className="icon-refresh">
                        <SVGIcons.RefreshIcon />
                      </div>
                    </Tooltip>
                  }
                </>
              )}
            </div>


          </Col>
          <Col xs={6} sm={12}>
            {isMobile ? (
              <AppMobileDateRangePicker
                disabled={isAllChartsEmpty}
                defaulValue={DateRangeValues.thisWeek}
                onChange={dateChange}
              />
            ) : (
              <AppDateRangePicker2
                disabled={isAllChartsEmpty}
                defaulValue={DateRangeValues.thisWeek}
                onChange={dateChange}
                isHomeDateRange={true}
              />
            )}
          </Col>
        </Row>
      </div>
      <div className="page-body">
        <div className="sales-analytics__session">
          <SpinDiv loading={usersInfoLoading || reportsLoading}>
            {isFetched ? (
              <SalesAnalyticsCharts selectedRangeName={selectedRangeName} />
            ) : (
              <></>
            )}
          </SpinDiv>
        </div>
      </div>
    </div>
  );
}

export default SalesAnalytics;
