import { AxiosResponse } from "axios";
import {
  ClientAccess,
  ConnectedZohoInfo,
  GetZohoPendingJobsResponse,
  OwnerModel,
  SalesAnalyticRequestModel,
  ZohoAdminClientInfo,
  ZohoClientInfo,
  ZohoReport,
  ZohoUserInfo,
} from "../models/zoho.model";
import { deleteAsync, getAsync, postAsync, putAsync } from "./http-client";

const baseUrl = `${process.env.REACT_APP_API_ENPOINT}/callrail/api/v1`;

function getConnectUrl(
  isAdmin: boolean,
  clientId: number,
  customRedirect: string
) {
  const url = `${baseUrl}/Zoho/connect-url`;
  return getAsync(url, { isAdmin, clientId, customRedirect });
}

function getAllUsersInfo(
  clientId: number,
  isAdmin: boolean
): Promise<AxiosResponse<ZohoUserInfo>> {
  const url = `${baseUrl}/Zoho/get-all-users-info`;
  return getAsync(url, { clientId, isAdmin });
}

function getAllAdminZoho(
  clientId: number
): Promise<AxiosResponse<ZohoAdminClientInfo>> {
  const url = `${baseUrl}/Zoho/get-all-admin-zoho-client`;
  return getAsync(url, { clientId });
}

function getAllZohoClient(
  clientId: number
): Promise<AxiosResponse<ZohoClientInfo[]>> {
  const url = `${baseUrl}/Zoho/get-all-zoho-client`;
  return getAsync(url, { clientId });
}

function deleteZohoClient(zohoClientId: number, clientId: number) {
  const url = `${baseUrl}/Zoho/connection?zohoClientId=${zohoClientId}&clientId=${clientId}`;
  return deleteAsync(url);
}

function getZohoReport(
  cardId: number,
  params: SalesAnalyticRequestModel
): Promise<AxiosResponse<ZohoReport>> {
  const url = `${baseUrl}/ZohoReport/detail/${cardId}`;
  return getAsync(url, params);
}

function getOrgOwners(orgId: number): Promise<AxiosResponse<OwnerModel[]>> {
  const url = `${baseUrl}/Zoho/org-owners`;
  return getAsync(url, { orgId });
}

function checkConnectedZoho(
  zohoClientId?: number
): Promise<AxiosResponse<ConnectedZohoInfo>> {
  const url = `${baseUrl}/Zoho/check-zoho-connected`;
  return getAsync(url, { zohoClientId }, false, false);
}

function getClientAccess(code: string, clientId: number, isAdmin: boolean, isReconnect?: boolean, isConnectNow?: boolean): Promise<AxiosResponse<ClientAccess>> {
  const url = `${baseUrl}/Zoho?code=${code}&clientId=${clientId}&isAdmin=${isAdmin}&isReconnect=${isReconnect}&isConnectNow=${isConnectNow}`;
  return putAsync(url, undefined, undefined, false);
}

function syncUsers(clientId: number, orgId: number) {
  const url = `${baseUrl}/Zoho/sync-users?clientId=${clientId}&orgId=${orgId}`;
  return postAsync(url);
}

function syncLatest(clientId: number, orgId?: number) {
  let url = `${baseUrl}/Zoho/sync-latest?clientId=${clientId}`;
  if(orgId) {
    url = url + '&orgId=' + orgId;
  }
  return postAsync(url);
}

function getPendingSyncJobs(
  clientId: number,
  orgId?: number
): Promise<AxiosResponse<GetZohoPendingJobsResponse>> {
  let url = `${baseUrl}/Zoho/pending-sync-jobs?clientId=${clientId}`;
  if(orgId) {
    url = url + '&orgId=' + orgId;
  }
  return getAsync(url);
}

function addMapZohoClient(clientId: number, zohoClientId: number) {
  const url = `${baseUrl}/Zoho/add-map?clientId=${clientId}&zohoClientId=${zohoClientId}`;
  return putAsync(url);
}

export const ZohoApi = {
  getZohoReport,
  getConnectUrl,
  getAllUsersInfo,
  getAllZohoClient,
  getOrgOwners,
  checkConnectedZoho,
  getClientAccess,
  syncUsers,
  syncLatest,
  getPendingSyncJobs,
  deleteZohoClient,
  getAllAdminZoho,
  addMapZohoClient,
};
