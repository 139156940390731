import i18n from "../utils/i18n";
import {
  ApprovalStatusEnum,
  BulkApprovalsStatus,
  ClientCategory,
  ContentErrorType,
  DateRangeValues,
  DeliveryFrequency,
  GmbActionType,
  PlanType,
  PostStatusType,
  ReportFormat,
  SocialType,
  UserPlan,
  ZohoReportType
} from "./app.enums";

export const PAGE_SIZE_DEFAULT = 10;

export const LOCAL_COOKIE_KEY = {
  ID_TOKEN: "ID_TOKEN",
  IS_LOGGED_IN: "IS_LOGGED_IN",
};

export const LOCAL_STORAGE_KEY = {
  LANGUAGE: "lang",
  USER_INFO: "USER_INFO",
  IS_REMEMBER_ME: "IS_REMEMBER_ME",
  REFRESH_TOKEN: "REFRESH_TOKEN",
  ACCESS_TOKEN: "ACCESS_TOKEN",
  GUEST_USER_INFO: "GUEST_USER_INFO",
};

export const VALIDATION_MESSAGE_CONFIG = {
  required: i18n.t("common.requireValidation"),
  types: {
    email: i18n.t("common.emailValidation"),
    url: i18n.t("common.urlValidation"),
  },
};

export const ANT_LOCALE = {
  emptyText: i18n.t("common.noData"),
};

export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])[A-Za-z0-9_@./#&+-]{8,}$/;

export const PASSWORD_REGEX_ONE_LOWER_CASE = `(?=.*[a-z])`; // should contain at least one lower case
export const PASSWORD_REGEX_ONE_UPPER_CASE = `(?=.*[A-Z])`; // should contain at least one upper case
export const PASSWORD_REGEX_ONE_NUMBER = `(?=.*[0-9])`; // should contain at least one upper case
export const PASSWORD_REGEX_MINIMUM_8_CHARACTERS = `(?=.{8,})`; // should contain at least 8 characters
export const PASSWROD_REGEX_SPECIAL_CHARACTER = `(?=.*[!@#$%^&*])`; // should contain a special character

export const PASSWORD_POLYCIES = [
  {
    key: 1,
    label: i18n.t("common.oneLowercaseCharacter"),
    valid: false,
    regex: PASSWORD_REGEX_ONE_LOWER_CASE,
  },
  {
    key: 2,
    label: i18n.t("common.oneUppercaseCharacter"),
    regex: PASSWORD_REGEX_ONE_UPPER_CASE,
    valid: false,
  },
  {
    key: 3,
    label: i18n.t("common.oneNumber"),
    regex: PASSWORD_REGEX_ONE_NUMBER,
    valid: false,
  },
  {
    key: 4,
    label: i18n.t("common.8CharactersMinimum"),
    regex: PASSWORD_REGEX_MINIMUM_8_CHARACTERS,
    valid: false,
  },
  {
    key: 5,
    label: i18n.t("common.containSpecialCharacter"),
    regex: PASSWROD_REGEX_SPECIAL_CHARACTER,
    valid: false,
    disabled: true,
  },
];

export const DATE_TIME_FORMAT = {
  dateTimeViewFormat: "MMM DD, YYYY HH:mm",
  dateTimeViewFormatPM: "MMM DD, YYYY h:mm a",
  viewFormat: "MMM DD, YYYY",
  dateView: "MMM D, YYYY",
  dateMonthView: "MMM D",
  monthView: "MMM YYYY",
  timeView: "h:mm:ss a",
  hourView: "h:mm a",
  isoDate: "yyyy-MM-DD",
  chartDateFormat: "MMM dd",
  dateTimeSecondViewFormat: "MMM D, YYYY, h:mm:ss a",
  isoDateStartOfDay: "yyyy-MM-DDT00:00:00",
  isoDateEndOfDay: "yyyy-MM-DDT23:59:00",
};

export const EMPTY_DATA_COLOR = "#e3e4e6";
export const CHART_COLORS = [
  "#0185de",
  "#9bc13c",
  "#f59f00",
  "#5cbae6",
  "#738fbd",
  "#d899cb",
  "#a1aac7",
  "#fac364",
];

export const REVIEW_NETWORK_DEFAULT_NAME = "REVIEW NETWORK";

export const REPORT_DELIVERY_FREQUENCY = [
  {
    key: DeliveryFrequency.Daily,
    name: i18n.t("generalSettings.everyday"),
  },
  {
    key: DeliveryFrequency.Weekly,
    name: i18n.t("generalSettings.everydays", { days: 7 }),
  },
  {
    key: DeliveryFrequency.Monthly,
    name: i18n.t("generalSettings.everydays", { days: 30 }),
  },
  {
    key: DeliveryFrequency.Quarterly,
    name: i18n.t("generalSettings.everydays", { days: 90 }),
  },
  {
    key: DeliveryFrequency.Yearly,
    name: i18n.t("generalSettings.everydays", { days: 365 }),
  },
];

export const REPORT_FORMATS = [
  {
    key: ReportFormat.CSV,
    name: "CSV",
    downloadLabel: i18n.t("generalSettings.downloadCSV"),
  },
  {
    key: ReportFormat.PDF,
    name: "PDF",
    downloadLabel: i18n.t("generalSettings.downloadPDF"),
  },
];

export const MAXIMUM_HASHTAG = 30;
export const CLIENT_CETEGORIES = [
  { value: ClientCategory.Uncategorised, name: "Uncategorised" },
  { value: ClientCategory.AnimalIndustry, name: "Animal Industry" },
  { value: ClientCategory.Automotive, name: "Automotive" },
  { value: ClientCategory.Construction, name: "Construction" },
  { value: ClientCategory.Dental, name: "Dental" },
  { value: ClientCategory.Education, name: "Education" },
  { value: ClientCategory.FinancingAndInsurance, name: "Financing & Insurance" },
  { value: ClientCategory.HealthAndBeauty, name: "Health & Beauty" },
  { value: ClientCategory.HomeServices, name: "Home Services" },
  { value: ClientCategory.IndependentConsultant, name: "Independent Consultant" },
  { value: ClientCategory.Industrial, name: "Industrial" },
  { value: ClientCategory.InformationTechnologies, name: "Information Technologies" },
  { value: ClientCategory.Legal, name: "Legal" },
  { value: ClientCategory.Logistics, name: "Logistics" },
  { value: ClientCategory.Marketing, name: "Marketing" },
  { value: ClientCategory.Medical, name: "Medical" },
  { value: ClientCategory.RealEstate, name: "Real Estate" },
  { value: ClientCategory.TravelAgency, name: "Travel Agency" },
];

export const US_STATES = [
  { value: "AK", name: "Alaska" },
  { value: "TX", name: "Texas" },
  { value: "AL", name: "Alabama" },
  { value: "AR", name: "Arkansas" },
  { value: "AZ", name: "Arizona" },
  { value: "CA", name: "California" },
  { value: "CO", name: "Colorado" },
  { value: "CT", name: "Connecticut" },
  { value: "DC", name: "DistrictofColumbia" },
  { value: "DE", name: "Delaware" },
  { value: "FL", name: "Florida" },
  { value: "GA", name: "Georgia" },
  { value: "HI", name: "Hawaii" },
  { value: "IA", name: "Iowa" },
  { value: "ID", name: "Idaho" },
  { value: "IL", name: "Illinois" },
  { value: "IN", name: "Indiana" },
  { value: "KS", name: "Kansas" },
  { value: "KY", name: "Kentucky" },
  { value: "LA", name: "Louisiana" },
  { value: "MA", name: "Massachusetts" },
  { value: "MD", name: "Maryland" },
  { value: "ME", name: "Maine" },
  { value: "MI", name: "Michigan" },
  { value: "MN", name: "Minnesota" },
  { value: "MO", name: "Missouri" },
  { value: "MS", name: "Mississippi" },
  { value: "MT", name: "Montana" },
  { value: "NC", name: "NorthCarolina" },
  { value: "ND", name: "NorthDakota" },
  { value: "NE", name: "Nebraska" },
  { value: "NH", name: "NewHampshire" },
  { value: "NJ", name: "NewJersey" },
  { value: "NM", name: "NewMexico" },
  { value: "NV", name: "Nevada" },
  { value: "NY", name: "NewYork" },
  { value: "OH", name: "Ohio" },
  { value: "OK", name: "Oklahoma" },
  { value: "OR", name: "Oregon" },
  { value: "PA", name: "Pennsylvania" },
  { value: "RI", name: "RhodeIsland" },
  { value: "SC", name: "SouthCarolina" },
  { value: "SD", name: "SouthDakota" },
  { value: "TN", name: "Tennessee" },
  { value: "TX", name: "Texas" },
  { value: "UT", name: "Utah" },
  { value: "VA", name: "Virginia" },
  { value: "VT", name: "Vermont" },
  { value: "WA", name: "Washington" },
  { value: "WI", name: "Wisconsin" },
  { value: "WV", name: "WestVirginia" },
  { value: "WY", name: "Wyoming" },
];

export const COUNTRIES_STATES = [
  {
    country: "Australia",
    code: "AU",
    states: [
      { name: "New South Wales", value: "NSW" },
      { name: "Queensland", value: "QLD" },
      { name: "South Australia", value: "SA" },
      { name: "Tasmania", value: "TAS" },
      { name: "Victoria", value: "VIC" },
      { name: "Western Australia", value: "WA" },
      { name: "Australian Capital Territory", value: "ACT" },
      { name: "Northern Territory", value: "NT" },
    ],
  },
  {
    country: "Austria",
    code: "AT",
    states: [
      { name: "Burgenland", value: "1" },
      { name: "Carinthia", value: "2" },
      { name: "Lower Austria", value: "3" },
      { name: "Upper Austria", value: "4" },
      { name: "Salzburg", value: "5" },
      { name: "Styria", value: "6" },
      { name: "Tyrol", value: "7" },
      { name: "Vorarlberg", value: "8" },
      { name: "Vienna", value: "9" },
    ],
  },
  {
    country: "Brazil",
    code: "BR",
    states: [
      { name: "Acre", value: "AC" },
      { name: "Alagoas", value: "AL" },
      { name: "Amazonas", value: "AM" },
      { name: "Amapá", value: "AP" },
      { name: "Bahia", value: "BA" },
      { name: "Ceará", value: "CE" },
      { name: "Distrito Federal", value: "DF" },
      { name: "Espírito Santo", value: "ES" },
      { name: "Goiás", value: "GO" },
      { name: "Maranhão", value: "MA" },
      { name: "Minas Gerais", value: "MG" },
      { name: "Mato Grosso do Sul", value: "MS" },
      { name: "Mato Grosso", value: "MT" },
      { name: "Pará", value: "PA" },
      { name: "Paraíba", value: "PB" },
      { name: "Pernambuco", value: "PE" },
      { name: "Piauí", value: "PI" },
      { name: "Paraná", value: "PR" },
      { name: "Rio de Janeiro", value: "RJ" },
      { name: "Rio Grande do Norte", value: "RN" },
      { name: "Rondônia", value: "RO" },
      { name: "Roraima", value: "RR" },
      { name: "Rio Grande do Sul", value: "RS" },
      { name: "Santa Catarina", value: "SC" },
      { name: "Sergipe", value: "SE" },
      { name: "São Paulo", value: "SP" },
      { name: "Tocantins", value: "TO" },
    ],
  },
  {
    country: "Germany",
    code: "DE",
    states: [
      { name: "Brandenburg", value: "BB" },
      { name: "Berlin", value: "BE" },
      { name: "Baden-Württemberg", value: "BW" },
      { name: "Bayern (Bavaria)", value: "BY" },
      { name: "Bremen", value: "HB" },
      { name: "Hesse (Hessen)", value: "HE" },
      { name: "Hamburg", value: "HH" },
      { name: "Mecklenburg-Vorpommern", value: "MV" },
      { name: "Niedersachsen (Lower Saxony)", value: "NI" },
      { name: "Nordrhein-Westfalen (North Rhine-Westphalia)", value: "NW" },
      { name: "Rheinland-Pfalz (Rhineland-Palatinate)", value: "RP" },
      { name: "Schleswig-Holstein", value: "SH" },
      { name: "Saarland", value: "SL" },
      { name: "Sachsen (Saxony)", value: "SN" },
      { name: "Sachsen-Anhalt (Saxony-Anhalt)", value: "ST" },
      { name: "Thüringen (Thuringia)", value: "TH" },
    ],
  },
  {
    country: "India",
    code: "IN",
    states: [
      { name: "Andhra Pradesh", value: "AP" },
      { name: "Arunachal Pradesh", value: "AR" },
      { name: "Assam", value: "AS" },
      { name: "Bihar", value: "BR" },
      { name: "Chhattisgarh", value: "CT" },
      { name: "Goa", value: "GA" },
      { name: "Gujarat", value: "GJ" },
      { name: "Haryana", value: "HR" },
      { name: "Himachal Pradesh", value: "HP" },
      { name: "Jammu and Kashmir", value: "JK" },
      { name: "Jharkhand", value: "JH" },
      { name: "Karnataka", value: "KA" },
      { name: "Kerala", value: "KL" },
      { name: "Madhya Pradesh", value: "MP" },
      { name: "Maharashtra", value: "MH" },
      { name: "Manipur", value: "MN" },
      { name: "Meghalaya", value: "ML" },
      { name: "Mizoram", value: "MZ" },
      { name: "Nagaland", value: "NL" },
      { name: "Odisha", value: "OR" },
      { name: "Punjab", value: "PB" },
      {
        name: "Rajasthan",
        value: "RJ",
      },
      {
        name: "Sikkim",
        value: "SK",
      },
      {
        name: "Tamil Nadu",
        value: "TN",
      },
      {
        name: "Telangana",
        value: "TG",
      },
      {
        name: "Tripura",
        value: "TR",
      },
      {
        name: "Uttarakhand",
        value: "UT",
      },
      {
        name: "Uttar Pradesh",
        value: "UP",
      },
      {
        name: "West Bengal",
        value: "WB",
      },
      {
        name: "Andaman and Nicobar Islands",
        value: "AN",
      },
      {
        name: "Chandigarh",
        value: "CH",
      },
      {
        name: "Dadra and Nagar Haveli",
        value: "DN",
      },
      {
        name: "Daman and Diu",
        value: "DD",
      },
      {
        name: "Delhi",
        value: "DL",
      },
      {
        name: "Lakshadweep",
        value: "LD",
      },
      {
        name: "Puducherry",
        value: "PY",
      },
    ],
  },
  {
    country: "Mexico",
    code: "MX",
    states: [
      { name: "Aguascalientes", value: "AG" },
      { name: "Baja California", value: "BC" },
      { name: "Baja California Sur", value: "BS" },
      { name: "Chihuahua", value: "CH" },
      { name: "Colima", value: "CL" },
      { name: "Campeche", value: "CM" },
      { name: "Coahuila", value: "CO" },
      { name: "Chiapas", value: "CS" },
      { name: "Federal District", value: "DF" },
      { name: "Durango", value: "DG" },
      { name: "Guerrero", value: "GR" },
      { name: "Guanajuato", value: "GT" },
      { name: "Hidalgo", value: "HG" },
      { name: "Jalisco", value: "JA" },
      { name: "México State", value: "ME" },
      { name: "Michoacán", value: "MI" },
      { name: "Morelos", value: "MO" },
      { name: "Nayarit", value: "NA" },
      { name: "Nuevo León", value: "NL" },
      { name: "Oaxaca", value: "OA" },
      { name: "Puebla", value: "PB" },
      { name: "Querétaro", value: "QE" },
      { name: "Quintana Roo", value: "QR" },
      { name: "Sinaloa", value: "SI" },
      { name: "San Luis Potosí", value: "SL" },
      { name: "Sonora", value: "SO" },
      {
        name: "Tabasco",
        value: "TB",
      },
      {
        name: "Tlaxcala",
        value: "TL",
      },
      {
        name: "Tamaulipas",
        value: "TM",
      },
      {
        name: "Veracruz",
        value: "VE",
      },
      {
        name: "Yucatán",
        value: "YU",
      },
      {
        name: "Zacatecas",
        value: "ZA",
      },
    ],
  },
  {
    country: "Nigeria",
    code: "NG",
    states: [
      {
        name: "Abuja",
        value: "FC",
      },
      {
        name: "Abia",
        value: "AB",
      },
      {
        name: "Adamawa",
        value: "AD",
      },
      {
        name: "Akwa Ibom",
        value: "AK",
      },
      {
        name: "Anambra",
        value: "AN",
      },
      {
        name: "Bauchi",
        value: "BA",
      },
      {
        name: "Bayelsa",
        value: "BY",
      },
      {
        name: "Benue",
        value: "BE",
      },
      {
        name: "Borno",
        value: "BO",
      },
      {
        name: "Cross River",
        value: "CR",
      },
      {
        name: "Delta",
        value: "DE",
      },
      {
        name: "Ebonyi",
        value: "EB",
      },
      {
        name: "Edo",
        value: "ED",
      },
      {
        name: "Ekiti",
        value: "EK",
      },
      {
        name: "Enugu",
        value: "EN",
      },
      {
        name: "Gombe",
        value: "GO",
      },
      {
        name: "Imo",
        value: "IM",
      },
      {
        name: "Jigawa",
        value: "JI",
      },
      {
        name: "Kaduna",
        value: "KD",
      },
      {
        name: "Kano",
        value: "KN",
      },
      {
        name: "atsina",
        value: "KT",
      },
      {
        name: "Kebbi",
        value: "KE",
      },
      {
        name: "Kogi",
        value: "KO",
      },
      {
        name: "Kwara",
        value: "KW",
      },
      {
        name: "Lagos",
        value: "LA",
      },
      {
        name: "Nassarawa",
        value: "NA",
      },
      {
        name: "Niger",
        value: "NI",
      },
      {
        name: "Ogun",
        value: "OG",
      },
      {
        name: "Ondo",
        value: "ON",
      },
      {
        name: "Osun",
        value: "OS",
      },
      {
        name: "Oyo",
        value: "OY",
      },
      {
        name: "Plateau",
        value: "PL",
      },
      {
        name: "Rivers",
        value: "RI",
      },
      {
        name: "Sokoto",
        value: "SO",
      },
      {
        name: "Taraba",
        value: "TA",
      },
      {
        name: "Yobe",
        value: "YO",
      },
      {
        name: "Zamfara",
        value: "ZA",
      },
    ],
  },
  {
    country: "Malaysia",
    code: "MY",
    states: [
      {
        name: "Johor",
        value: "JHR",
      },
      {
        name: "Kedah",
        value: "KDH",
      },
      {
        name: "Kelantan",
        value: "KTN",
      },
      {
        name: "Melaka",
        value: "MLK",
      },
      {
        name: "Negeri Sembilan",
        value: "NSN",
      },
      {
        name: "Pahang",
        value: "PHG",
      },
      {
        name: "Perak",
        value: "PRK",
      },
      {
        name: "Perlis",
        value: "PLS",
      },
      {
        name: "Pulau Pinang",
        value: "PNG",
      },
      {
        name: "Sabah",
        value: "SBH",
      },
      {
        name: "Sarawak",
        value: "SWK",
      },
      {
        name: "Selangor",
        value: "SGR",
      },
      {
        name: "Terengganu",
        value: "TRG",
      },
      {
        name: "W.P. Kuala Lumpur",
        value: "KUL",
      },
      {
        name: "W.P. Labuan",
        value: "LBN",
      },
      {
        name: "W.P. Putrajaya",
        value: "PJY",
      },
    ],
  },
  {
    country: "Myanmar",
    code: "MM",
    states: [
      {
        name: "Ayeyarwady",
        value: "AYE",
      },
      {
        name: "Bago",
        value: "BAG",
      },
      {
        name: "Chin",
        value: "CHI",
      },
      {
        name: "Kachin",
        value: "KAC",
      },
      {
        name: "Kayah",
        value: "KYH",
      },
      {
        name: "Kayin",
        value: "KYN",
      },
      {
        name: "Magway",
        value: "MAG",
      },
      {
        name: "Mandalay",
        value: "MAN",
      },
      {
        name: "Mon",
        value: "MON",
      },
      {
        name: "Naypyitaw",
        value: "NAY",
      },
      {
        name: "Rakhine",
        value: "RAK",
      },
      {
        name: "Sagaing",
        value: "SAG",
      },
      {
        name: "Shan",
        value: "SHA",
      },
      {
        name: "Tanintharyi",
        value: "TAN",
      },
      {
        name: "Yangon",
        value: "YAN",
      },
    ],
  },
  {
    country: "New Zealand",
    code: "NZ",
    states: [
      {
        name: "Auckland",
        value: "AUK",
      },
      {
        name: "New Plymouth",
        value: "NPL",
      },
      {
        name: "Wellington",
        value: "WGN",
      },
      {
        name: "Nelson",
        value: "NSN",
      },
      {
        name: "Canterbury",
        value: "CAN",
      },
      {
        name: "Otago",
        value: "OTA",
      },
    ],
  },
  {
    country: "United States",
    code: "US",
    states: [
      { value: "AK", name: "Alaska" },
      { value: "TX", name: "Texas" },
      { value: "AL", name: "Alabama" },
      { value: "AR", name: "Arkansas" },
      { value: "AZ", name: "Arizona" },
      { value: "CA", name: "California" },
      { value: "CO", name: "Colorado" },
      { value: "CT", name: "Connecticut" },
      { value: "DC", name: "DistrictofColumbia" },
      { value: "DE", name: "Delaware" },
      { value: "FL", name: "Florida" },
      { value: "GA", name: "Georgia" },
      { value: "HI", name: "Hawaii" },
      { value: "IA", name: "Iowa" },
      { value: "ID", name: "Idaho" },
      { value: "IL", name: "Illinois" },
      { value: "IN", name: "Indiana" },
      { value: "KS", name: "Kansas" },
      { value: "KY", name: "Kentucky" },
      { value: "LA", name: "Louisiana" },
      { value: "MA", name: "Massachusetts" },
      { value: "MD", name: "Maryland" },
      { value: "ME", name: "Maine" },
      { value: "MI", name: "Michigan" },
      { value: "MN", name: "Minnesota" },
      { value: "MO", name: "Missouri" },
      { value: "MS", name: "Mississippi" },
      { value: "MT", name: "Montana" },
      { value: "NC", name: "NorthCarolina" },
      { value: "ND", name: "NorthDakota" },
      { value: "NE", name: "Nebraska" },
      { value: "NH", name: "NewHampshire" },
      { value: "NJ", name: "NewJersey" },
      { value: "NM", name: "NewMexico" },
      { value: "NV", name: "Nevada" },
      { value: "NY", name: "NewYork" },
      { value: "OH", name: "Ohio" },
      { value: "OK", name: "Oklahoma" },
      { value: "OR", name: "Oregon" },
      { value: "PA", name: "Pennsylvania" },
      { value: "RI", name: "RhodeIsland" },
      { value: "SC", name: "SouthCarolina" },
      { value: "SD", name: "SouthDakota" },
      { value: "TN", name: "Tennessee" },
      { value: "TX", name: "Texas" },
      { value: "UT", name: "Utah" },
      { value: "VA", name: "Virginia" },
      { value: "VT", name: "Vermont" },
      { value: "WA", name: "Washington" },
      { value: "WI", name: "Wisconsin" },
      { value: "WV", name: "WestVirginia" },
      { value: "WY", name: "Wyoming" },
    ],
  },
];

export const USER_FILTER_DATA = {
  role: [
    { name: "Super Admin", value: "Super Admin" },
    { name: "Editor", value: "Editor" },
    { name: "Client", value: "Client" },
  ],
  userGroup: [
    { name: "Super Admin", value: "Super Admin" },
    { name: "Editor", value: "Editor" },
    { name: "Client", value: "Client" },
    { name: "Uncategorised", value: "Uncategorised" },
  ],
  // status: [
  //   { name: "Active", value: "active" },
  //   { name: "Disabled", value: "deactive" },
  // ],
};

export const USER_GROUP_FILTER_DATA = {
  role: [
    { name: "Super Admin", value: "Super Admin" },
    { name: "Editor", value: "Editor" },
    { name: "Client", value: "Client" },
  ],
};

export const CLIENT_FILTER_DATA = {
  status: [
    { name: "Active", value: "active" },
    { name: "Error", value: "error" },
    { name: "Disabled", value: "deactive" },
  ],
  category: CLIENT_CETEGORIES,
};

export const CONTENT_FILTER_DATA = {
  platforms: [
    { name: "Facebook", value: SocialType.Facebook },
    { name: "Instagram", value: SocialType.Instagram },
    { name: "Tiktok", value: SocialType.Tiktok },
    { name: "Youtube", value: SocialType.Youtube },
    { name: "Goole Business Profile", value: SocialType.Google },
  ],
  statuses: [
    { name: "Draft", value: PostStatusType.Drafted },
    { name: "Post with Errors", value: PostStatusType.Error },
    { name: "Published", value: PostStatusType.Posted },
    { name: "Scheduled", value: PostStatusType.Scheduled },
  ],
  approvalStatus: [
    { name: "Approved", value: ApprovalStatusEnum.Approved },
    { name: "Declined", value: ApprovalStatusEnum.Declined },
    { name: "Not required", value: ApprovalStatusEnum.None },
    { name: "Pending", value: ApprovalStatusEnum.Pending },
  ],
};

export const CALENDAR_FILTER_DATA = {
  platforms: [
    { name: "Facebook", value: SocialType.Facebook },
    { name: "Instagram", value: SocialType.Instagram },
    { name: "Tiktok", value: SocialType.Tiktok },
    { name: "Youtube", value: SocialType.Youtube },
    { name: "Goole Business Profile", value: SocialType.Google },
  ],
  statuses: [
    { name: "Post with Errors", value: PostStatusType.Error },
    { name: "Published", value: PostStatusType.Posted },
    { name: "Scheduled", value: PostStatusType.Scheduled },
  ],
  approvalStatus: [
    { name: "Approved", value: ApprovalStatusEnum.Approved },
    { name: "Declined", value: ApprovalStatusEnum.Declined },
    { name: "Not required", value: ApprovalStatusEnum.None },
    { name: "Pending", value: ApprovalStatusEnum.Pending },
  ],
};

export const APPROVAL_FILTER_DATA = {
  approvalStatus: [
    { name: "Pending", value: BulkApprovalsStatus.Pending },
    { name: "Approved", value: BulkApprovalsStatus.Approved },
    { name: "Declined", value: BulkApprovalsStatus.Declined },
    { name: "Expired", value: BulkApprovalsStatus.Expired },
  ],
  withDueDate: [
    { name: "Yes", value: "true" },
    { name: "No", value: "false" },
  ],
};

export const SORTER_MAPPING_VALUE: any = {
  id: "Id",
  fullName: "UserName",
  userGroups: "UserGroup",
  roles: "Role",
  lastLoginDate: "LastLoginDate",
  createdDate: "CreatedDate",
  companyName: "CompanyName",
  category: "Category",
  clientStatus: "Status",
  createdOn: "CreatedOn",
  lastLogin: "LastLogin",
  loggedInBy: "LastLoginBy",
  ascend: "asc",
  descend: "desc",
  plan: "plan"
};

export const FILTER_MAPPING_VALUE: any = {
  role: "userRoles",
  userGroup: "userGroups",
  status: "statuses",
  category: "categories",
  plan: "plans",
};

export const SUPPORT_EMAIL = "support@rocketlevel.com";
export const BILLING_SUBJECT = "Billing Assistance Needed - ";

export const listSocialContentType = [
  SocialType.Facebook,
  SocialType.Instagram,
  SocialType.Tiktok,
];

export const LIMIT_CHARACTER_FB = 5000;
export const LIMIT_CHARACTER_INS = 2200;
export const LIMIT_CHARACTER_TIKTOK = 2200;
export const LIMIT_CHARACTER_YOUTUBE = 5000;
export const LIMIT_CHARACTER_YOUTUBE_TITLE = 100;
export const LIMIT_CHARACTER_GOOGLE = 1500;

export const ErrorContentText = {
  [ContentErrorType.RequiredPost]: "Caption is required",
  [ContentErrorType.RequiredPostFB]: "Facebook requires content",
  [ContentErrorType.RequiredPostInsta]: "Instagram requires content",
  [ContentErrorType.RequiredPostTiktok]: "Tiktok requires content",
  [ContentErrorType.RequiredPostYoutube]: "Youtube requires content",
  [ContentErrorType.RequiredPostGBP]: "GBP requires content",
  [ContentErrorType.RequiredMediaFB]: "Facebook requires at least 1 media",
  [ContentErrorType.RequiredMediaFbReel]: "Facebook Reel requires a media",
  [ContentErrorType.RequiredMediaIns]: "Instagram requires at least 1 media",
  [ContentErrorType.RequiredMediaInsReel]: "Instagram Reel requires a media",
  [ContentErrorType.RequiredMediaTiktok]: "Tiktok requires at least 1 media",
  [ContentErrorType.RequiredMediaYoutube]: "Youtube requires at least 1 media",
  [ContentErrorType.RequiredMediaYoutubeShort]: "Youtube Short requires at least 1 media",
  [ContentErrorType.RequiredMediaGBPPhoto]: "Google Business Profile requires at least 1 media.",
  [ContentErrorType.LimitPostFB]:
    "Character limits exceeded. Edit the caption or turn off Sync Content to customise content for Facebook.",
  [ContentErrorType.LimitPostIns]:
    "Character limits exceeded. Edit the caption or turn off Sync Content to customise content for Instagram.",
  [ContentErrorType.LimitPostTiktok]:
    "Character limits exceeded. Edit the caption or turn off Sync Content to customise content for Tiktok.",
  [ContentErrorType.LimitPostYoutube]:
    "Character limits exceeded. Edit the caption or turn off Sync Content to customise content for Youtube.",
  [ContentErrorType.LimitReel]:
    "Character limits exceeded.",
  [ContentErrorType.LimitPostGBP]:
    "Character limits exceeded.",
};

export const MAXIMUM_VIDEO_SIZE = 157286400;
export const MAXIMUM_IMAGE_SIZE = 104857600;

export const DefaultSocialDateReport = {
  total: 0,
  volatility: 0,
  rows: [],
};

export const DefaultFollowersReport = {
  total: {
    totalFollowers: 0,
    newFollowers: 0,
  },
  volatility: {
    totalFollowers: 0,
    newFollowers: 0,
  },
  rows: [],
};

export const PLAN_CHECK_MESSAGES = {
  USER: "User has reached limit, please view or upgrade plan",
  CLIENT: "Client has reached limit, please view or upgrade plan",
  CANCELED: "Cannot proceed due to canceled plan. Renew your plan now to continue enjoying Premium features!",
};

export const TRIAL_REMINDER = {
  FIRST: 7, // days remaining
  SECOND: 48 // hours remaining
}

export const PLANS = [
  { value: PlanType.Free, name: UserPlan.FreePlan },
  { value: PlanType.Trial, name: UserPlan.FreeTrial },
  { value: PlanType.Premium, name: UserPlan.Premium },
  { value: PlanType.None, name: UserPlan.None },
];

export const UNSPLASH_UTM_SUFFIX_URL = "?utm_source=RocketLevel&utm_medium=referral"

export const GMB_ACTION_BUTTONS = [
  { value: GmbActionType.None, name: "None" },
  { value: GmbActionType.Book, name: "Book" },
  { value: GmbActionType.OrderOnline, name: "Order Online" },
  { value: GmbActionType.Buy, name: "Buy" },
  { value: GmbActionType.LearnMore, name: "Learn More" },
  { value: GmbActionType.SignUp, name: "Sign Up" },
  { value: GmbActionType.CallNow, name: "Call Now" },
];

export const ZohoCardTypes = [
  { type: ZohoReportType.TOTAL_LEADS_WORKED, name: i18n.t("salesAnalytics.totalLeadsWorked") },
  { type: ZohoReportType.TOTAL_CALL, name: i18n.t("salesAnalytics.totalCalls") },
  { type: ZohoReportType.TOTAL_DEMO_BOOKED, name: i18n.t("salesAnalytics.totalDemoBooked") },
  { type: ZohoReportType.CALL_COUNT, name: i18n.t("salesAnalytics.callCount") },
  { type: ZohoReportType.CONNECTION_RATE, name: i18n.t("salesAnalytics.connectionRate") },
  { type: ZohoReportType.CALL_STATUS, name: i18n.t("salesAnalytics.callStatus") },
  { type: ZohoReportType.BOOKING_COUNT, name: i18n.t("salesAnalytics.bookingCount") },
  { type: ZohoReportType.CONNECTED_BOOKING_RATE, name: i18n.t("salesAnalytics.connectedToBookingDate") },
];

export const DateRangeList = [
  {
    text: i18n.t("common.lastWeek"),
    key: DateRangeValues.last7days,
    displayText: i18n.t("common.lastWeekTitle"),
  },
  {
    text: i18n.t("common.lastMonth"),
    key: DateRangeValues.lastMonth,
    displayText: i18n.t("common.lastMonthTitle"),
  },
  {
    text: i18n.t("common.last3Months"),
    key: DateRangeValues.last3Months,
    displayText: i18n.t("common.last3MonthsTitle"),
  },
  {
    text: i18n.t("common.lastYear"),
    key: DateRangeValues.lastYear,
    displayText: i18n.t("common.lastYearTitle"),
  },
  {
    text: i18n.t("common.customRange"),
    key: DateRangeValues.customRange,
  },
  {
    text: i18n.t("common.thisWeek"),
    key: DateRangeValues.thisWeek,
    displayText: i18n.t("common.thisWeekTitle"),
  },
  {
    text: i18n.t("common.thisMonth"),
    key: DateRangeValues.thisMonth,
    displayText: i18n.t("common.thisMonthTitle"),
  },
  {
    text: i18n.t("common.thisYear"),
    key: DateRangeValues.thisYear,
    displayText: i18n.t("common.thisYearTitle"),
  },
];


export const ZohoRedirectUrl = {
  SaleAnalytic: "1",
  AdminViewSaleAnalytic: "2",
  GeneralSettings: "3",
  AdminViewGeneralSettings: "4",
  AdminEditSite: "5",
  AdminEditSiteShowModal: "6",
  ReAuthenticate: "7",
}
