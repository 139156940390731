import { Tooltip } from "antd";
import {
  ApprovalActivityType,
} from "../../../../../../constants/app.enums";
import useClient from "../../../../../../hooks/useClient";
import { ContenApprovalModel } from "../../../../../../models/approval.model";
import {
  ApprovalAcvitityModel,
} from "../../../../../../models/content.model";
import DateUtils from "../../../../../../utils/date.utils";
import SVGIcons from "../../../../../icons/svgs";
import "./index.scss";
import moment from "moment";

interface ActivityApprovalItemProps {
  activityInfo: ApprovalAcvitityModel;
  approval: ContenApprovalModel | undefined;
}
const ActivityApprovalItem = (props: ActivityApprovalItemProps) => {
  const { activityInfo, approval } = props;
  const { client } = useClient();

  const { type, userFullName, guestFullName, comment, createdDate } = activityInfo;

  const fullName = userFullName || guestFullName;

  const [icon, description] = (() => {
    switch (type) {
      case ApprovalActivityType.Create:
        return [<SVGIcons.HandRockIcon />, `Approval created by ${fullName}`];
      case ApprovalActivityType.Update:
        return [
          <SVGIcons.PencilMinusIcon />,
          `Approval modified by ${fullName}`,
        ];
      case ApprovalActivityType.ChangeDueDate:
        return [
          <SVGIcons.ClockEditIcon />,
          `Due Date changed to ${DateUtils.getDateWithTimezone(
            comment,
            client?.timeZoneInfo?.id
          ).format("MMM DD, YYYY")}`,
        ];
      case ApprovalActivityType.OneApprove:
        return [<SVGIcons.UserCheckIcon />, `Content approved by ${fullName}`];
      case ApprovalActivityType.OneDecline:
        return [<SVGIcons.UserXIcon />, `Content declined by ${fullName}`];
      case ApprovalActivityType.ApproversNotified:
        return [
          <SVGIcons.UserExclamationIcon />,
          `[approver-name] notified for approval`,
        ];

      case ApprovalActivityType.ApproveAllEnable:
        return [
          <SVGIcons.ClockEditIcon />,
          `${fullName} enabled Automatically Approve All after ${activityInfo?.comment} days`,
        ];
      case ApprovalActivityType.ApproveAllModify:
        return [
          <SVGIcons.ClockEditIcon />,
          `Automatically Approve All after ${activityInfo?.comment} days - updated by ${fullName}`,
        ];
      case ApprovalActivityType.AllContentsApproved:
        return [
          <SVGIcons.ConfettiIcon />,
          `All contents approved by ${fullName}`,
        ];
      case ApprovalActivityType.AllContentsDeclined:
        return [
          <SVGIcons.RubberStampIcon />,
          `All contents declined by ${fullName}`,
        ];
      case ApprovalActivityType.AllContentsApprovedAutomatically:
        return [
          <SVGIcons.ConfettiIcon />,
          'All contents approved automatically',
        ];
      default:
        return [<></>, ""];
    }
  })();

  const isRedItem = [
    ApprovalActivityType.AllContentsDeclined,
    ApprovalActivityType.OneDecline,
  ].includes(type);

  const isGreenItem = [ApprovalActivityType.AllContentsApproved].includes(type) || [ApprovalActivityType.AllContentsApprovedAutomatically].includes(type);

  return (
    <div
      className={`activity-item ${isRedItem ? "activity-item-red" : ""} ${isGreenItem ? "activity-item-green" : ""
        }`}
    >
      <div className="activity-item__icon">
        <div className="overlay"></div>
        <div className="icon">{icon}</div>
      </div>
      <div className="activity-item__detail">
        <div className="activity-item__detail-description">{description}</div>
        <Tooltip
          title={DateUtils.getDateWithTimezone(
            createdDate,
            client?.timeZoneInfo?.id
          ).format("MMM DD, YYYY, hh:mma")}
        >
          <span className="activity-item__detail-time">
            {moment.utc(createdDate).fromNow()}
          </span>
        </Tooltip>
      </div>
    </div>
  );
};

export default ActivityApprovalItem;
