import { Button, Col, Divider, InputNumber, Row, Switch, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthApi } from "../../../../../apis/auth.api";
import useClient from "../../../../../hooks/useClient";
import showNotification from "../../../../common/notification";
import SVGIcons from "../../../../icons/svgs";
import { PercentageOutlined } from '@ant-design/icons';


function SettingTab() {
  const { t } = useTranslation();
  const [averageValue, setAverageValue] = useState(0);
  const [conversionRate, setConversionRate] = useState(30);
  const [timeZoneSelected, setTimeZoneSelected] = useState<string | undefined>(
    undefined
  );

  const { client, setClient, clientId, isOwner } = useClient();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [showTrendIndicator, setShowTrendIndicator] = useState(false);

  useEffect(() => {
    updateValueFromClient();
  }, [client]);

  function updateValueFromClient() {
    setAverageValue(client?.averageValueOfNewCustomer || 0);
    setConversionRate(client?.conversionRate || 0);
    setShowTrendIndicator(client?.showTrendIndicator || false);
    setTimeZoneSelected(client?.timeZoneInfo?.id);
  }

  const onChangeValue = (value: number) => {
    setAverageValue(value);
  };

  const onChangeConversionRate = (value: number) => {
    setConversionRate(value);
  };

  const onClickCancel = () => {
    setAverageValue(client?.averageValueOfNewCustomer || 0);
  };

  const onResetConversionRate = () => {
    setConversionRate(client?.conversionRate || 0);
  }

  const onClickSave = async () => {
    setSubmitLoading(true);
    await saveAdvancedSettings();
    setSubmitLoading(false);
  };

  const saveAdvancedSettings = async () => {
    try {
      await AuthApi.updateAverageValueOfNewCustomer(client?.id, averageValue, conversionRate, showTrendIndicator);
      if (client) {
        setClient({
          ...client,
          averageValueOfNewCustomer: averageValue,
          conversionRate,
          showTrendIndicator
        });
        showNotification("success", t(`common.yourChangesSaveSuccess`));
      }
    } catch { }
  };

  return (
    <div className="setting-tab">
      <div className="title">
        <span>{t("generalSettings.dashboardSettings")}</span>
      </div>
      <div className="average-value">
        <div className="label">{t("generalSettings.optimizeYourMetrics")}</div>


        <div className="fields-wrapper">
          <div className="left" >
            <div>{t("generalSettings.averageValue")}</div>
            <div className="average-value__input">
              <Tooltip
                overlayClassName="custom-tooltip"
                placement="top"
                title={t("generalSettings.averageValueTooltip")}
              >
                <div className="icon">
                  <span>?</span>
                </div>
              </Tooltip>
            </div>
          </div>
          <div>
            <div className="average-value__input">
              <InputNumber
                addonBefore={<SVGIcons.DollarIcon />}
                value={averageValue}
                onChange={(value) => onChangeValue(value || 0)}
                disabled={!client || !isOwner()}
                min={0}
              />

              <Button
                disabled={!isOwner()}
                className="mid-night-border reset-btn"
                onClick={onClickCancel}
              >
                {t("common.reset")}
              </Button>
            </div>
          </div>
        </div>

        <div className="fields-wrapper">
          <div className="left" >
            <div>{t("generalSettings.conversionRate")}</div>
          </div>
          <div>
            <div className="average-value__input">
              <InputNumber
                className="input-right-addon"
                addonAfter={<PercentageOutlined />}
                value={conversionRate}
                onChange={(value) => onChangeConversionRate(value || 0)}
                disabled={!client || !isOwner()}
                min={0} 
                precision={2}
              />


              <Button
                disabled={!isOwner()}
                className="mid-night-border reset-btn"
                onClick={onResetConversionRate}
              >
                {t("common.reset")}
              </Button>
            </div>
          </div>
        </div>

        <div className="fields-wrapper">
          <div className="left" >
            <div>{t("generalSettings.trendIndicators")}</div>
            <div className="average-value__input">
              <Tooltip
                overlayClassName="custom-tooltip"
                placement="top"
                title={t("generalSettings.trendIndicatorTooltip")}
              >
                <div className="icon">
                  <span>?</span>
                </div>
              </Tooltip>
            </div>
          </div>
          <div>
            <Switch
              checked={showTrendIndicator}
              onChange={(value) => setShowTrendIndicator(value)}
            />
          </div>
        </div>
      </div>
      <Divider />
      <div className="save-btn">
        <Button
          onClick={onClickSave}
          type="primary"
          loading={submitLoading}
          disabled={
            !client ||
            (client.averageValueOfNewCustomer === averageValue 
              && client.conversionRate === conversionRate
              && client.showTrendIndicator === showTrendIndicator 
              && client.timeZoneInfo?.id === timeZoneSelected)

          }
        >
          {t("common.saveChanges")}
        </Button>
      </div>
    </div>
  );
}

export default SettingTab;
