import { Button } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ConnectionTab from "./connections";
import "./index.scss";
import SettingTab from "./settings";
import NotificationsTab from "./notifications";

enum AdvancedTab {
  Connection,
  Settings,
  Notifications
}

function GeneralSettingAdvanced() {
  const { t } = useTranslation();

  const [tab, setTab] = useState(AdvancedTab.Connection);

  const renderContent = () => {
    switch (tab) {
      case AdvancedTab.Connection:
        return <ConnectionTab />;
      case AdvancedTab.Settings:
        return <SettingTab />;
      case AdvancedTab.Notifications:
        return <NotificationsTab />;
      default:
        return "";
    }
  };

  return (
    <div className="general-setting-advanced">
      <div className="advanced-tab">
        <Button
          type={tab === AdvancedTab.Connection ? "primary" : "link"}
          onClick={() => setTab(AdvancedTab.Connection)}
        >
          Connections
        </Button>
        <Button
          type={tab === AdvancedTab.Notifications ? "primary" : "link"}
          onClick={() => setTab(AdvancedTab.Notifications)}
        >
          Notifications
        </Button>
        <Button
          type={tab === AdvancedTab.Settings ? "primary" : "link"}
          onClick={() => setTab(AdvancedTab.Settings)}
        >
          Other Settings
        </Button>
      </div>

      {renderContent()}
    </div>
  );
}

export default GeneralSettingAdvanced;
