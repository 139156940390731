import { Col, Row, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import {
  dasboardDateRangeState,
  dashboardGaGeneralState,
  dashboardTotalLeadsState,
  totalLeadsChartDataSelector,
  uniqueSearchSelector,
} from "../../../../states/dashboard";
import { NUMBER_UTIL } from "../../../../utils/number.utils";
import SpinDiv from "../../../common/spin-div";
import BarChart from "../charts/bar";
import LineInvestmentChart from "../charts/line-investment";
import TopLeadSourcesChart from "../charts/top-Lead-sources";
import VisitorChart from "../charts/visitors";
import "./index.scss";
import Volatility from "../../../common/volatility";

function Performance() {
  const { t } = useTranslation();
  const gaGeneral = useRecoilValue(dashboardGaGeneralState);
  const dateRange = useRecoilValue(dasboardDateRangeState);
  const totalLeads = useRecoilValue(dashboardTotalLeadsState);
  const totalLeadsChartData = useRecoilValue(totalLeadsChartDataSelector);
  const uniqueSearchsData = useRecoilValue(uniqueSearchSelector);
  const total = totalLeads?.data?.total || 0;
  const volatility = totalLeads?.data?.volatility || 0;
  const avgValueOfNewCustomer = totalLeads?.data?.avgValueOfNewCustomer || 0;
  const conversionRate = (totalLeads?.data?.conversionRate || 0) / 100;

  return (
    <div className="home-performance">
      <Row gutter={16}>
        <Col xs={24} sm={12} xl={8} className="col-total-lead">
          <div className="rl-card">
            <p className="rl-card-title">
              <span>{t("dashboard.totalLeads")}</span>
              <span>{dateRange.totalDays}d</span>
            </p>

            <SpinDiv loading={totalLeads.loading} style={{ height: 124 }}>
              <>
                <div className="home-performance__cardSum">
                  <p>{NUMBER_UTIL.convertNumericToFormattedString(total)}</p>
                  <Volatility value={volatility} />
                </div>
                <BarChart
                  title={t("dashboard.totalLeads")}
                  height={100}
                  chartId="leadsChart"
                  data={totalLeadsChartData}
                />
              </>
            </SpinDiv>
          </div>
        </Col>

        <Col xs={24} sm={24} xl={8} className="col-roi">
          <div className="rl-card">
            <p className="rl-card-title">
              <span>{t("dashboard.returnOnInvestment")}</span>
              <span>{dateRange.totalDays}d</span>
            </p>
            <SpinDiv loading={totalLeads.loading} style={{ height: 124 }}>
              <>
                <div className="home-performance__cardSum">
                  <p>
                    {NUMBER_UTIL.convertNumericToFormattedAmount(
                      total * avgValueOfNewCustomer * conversionRate
                    )}
                  </p>
                  <Volatility value={volatility} />
                </div>
                <LineInvestmentChart
                  height={112}
                  chartId="returnOnInvestmentChart"
                />
              </>
            </SpinDiv>
          </div>
        </Col>

        <Col xs={24} sm={12} xl={8} className="col-unique-search">
          <div className="rl-card">
            <p className="rl-card-title">
              <span>{t("dashboard.totalUniqueSearch")}</span>
              <span>{dateRange.totalDays}d</span>
            </p>
            <SpinDiv loading={gaGeneral.loading} style={{ height: 124 }}>
              <>
                <div className="home-performance__cardSum">
                  <p>
                    {NUMBER_UTIL.convertNumericToFormattedString(
                      gaGeneral?.data?.total?.newUsers
                    )}
                  </p>
                  <Volatility value={gaGeneral?.data?.volatility?.newUsers || 0} />
                </div>

                <BarChart
                  title={t("dashboard.totalUniqueSearch")}
                  height={100}
                  chartId="uniqueSearchChart"
                  data={uniqueSearchsData}
                />
              </>
            </SpinDiv>
          </div>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={12} xl={12}>
          <div className="rl-card">
            <p className="rl-card-title mr-bottom-24">
              <span>{t("dashboard.trafficBreakdown")}</span>
              <span>{dateRange.totalDays}d</span>
            </p>
            <TopLeadSourcesChart chartId="topLeadSourcesChart" />
          </div>
        </Col>

        <Col xs={24} sm={24} md={12} xl={12}>
          <div className="rl-card">
            <p className="rl-card-title mr-bottom-24">
              <span>{t("dashboard.visitorSession")}</span>
              <span>{dateRange.totalDays}d</span>
            </p>
            <VisitorChart chartId="visitorChart" />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Performance;
