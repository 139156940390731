import { Switch } from "antd";
import { useTranslation } from "react-i18next";
import { NotificationSettings } from "../../../../../models/notification.model";
import "./index.scss";

type Props = {
  settings: NotificationSettings;
  setSettings: (settings: NotificationSettings) => void;
};

function EditNotifications(props: Props) {
  const { t } = useTranslation();

  const { settings, setSettings } = props;

  return (
    <>
      <div className="edit-notifications">
        <div className="card-wrapper">
          <div className="card">
            <div className="card-title">{t("generalSettings.dashboards")}</div>

            <div className="switches">
              <div>
                <Switch
                  checked={settings.dbConnectionLost}
                  onChange={(value) =>
                    setSettings({
                      ...settings,
                      dbConnectionLost: value,
                    })
                  }
                />
                {t("generalSettings.aConnectionIsLost")}
              </div>

              <div>
                <Switch
                  checked={settings.dbDataSyncFailed}
                  onChange={(value) =>
                    setSettings({
                      ...settings,
                      dbDataSyncFailed: value,
                    })
                  }
                />
                {t("generalSettings.aDataSyncFails")}
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-title">
              {t("generalSettings.notificationsSocial")}
            </div>
            <div className="switches">
              <div>
                <Switch
                  checked={settings.socialContentFailed}
                  onChange={(value) =>
                    setSettings({
                      ...settings,
                      socialContentFailed: value,
                    })
                  }
                />
                {t("generalSettings.aContentFailsToPublish")}
              </div>

              <div>
                <Switch
                  checked={settings.socialContentSuccessfully}
                  onChange={(value) =>
                    setSettings({
                      ...settings,
                      socialContentSuccessfully: value,
                    })
                  }
                />
                {t("generalSettings.aContentIsPublishedSuccessfully")}
              </div>

              <div>
                <Switch
                  checked={settings.socialRequireApproval}
                  onChange={(value) =>
                    setSettings({
                      ...settings,
                      socialRequireApproval: value,
                    })
                  }
                />
                {t("generalSettings.aContentRequiresApproval")}
              </div>

              <div>
                <Switch
                  checked={settings.socialContentApproved}
                  onChange={(value) =>
                    setSettings({
                      ...settings,
                      socialContentApproved: value,
                    })
                  }
                />
                {t("generalSettings.aContentIsApproved")}
              </div>

              <div>
                <Switch
                  checked={settings.socialContentDeclined}
                  onChange={(value) =>
                    setSettings({
                      ...settings,
                      socialContentDeclined: value,
                    })
                  }
                />
                {t("generalSettings.aContentIsDeclined")}
              </div>

              <div>
                <Switch
                  checked={settings.socialNewComment}
                  onChange={(value) =>
                    setSettings({
                      ...settings,
                      socialNewComment: value,
                    })
                  }
                />
                {t("generalSettings.aContentHasANewComment")}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="notification-desc">
        {t("clientSites.editNotificationsDesc")}
      </div>
    </>
  );
}

export default EditNotifications;
