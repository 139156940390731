import {
  Badge,
  Button,
  Col,
  Drawer,
  Dropdown,
  Form,
  Input,
  Modal,
  Row,
  Table,
  Tabs,
  Typography,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { AuthApi } from "../../../apis/auth.api";
import FilterList from "../../../components/common/filter";
import showNotification from "../../../components/common/notification";
import SVGIcons from "../../../components/icons/svgs";
import Activities from "../../../components/views/client-sites/activities";
import CreateSiteForm from "../../../components/views/client-sites/create-form";
import IntergrationForm from "../../../components/views/client-sites/intergration-form";
import OverviewForm from "../../../components/views/client-sites/overview-form";
import ServicesForm from "../../../components/views/client-sites/service-form";
import BulkAction from "../../../components/views/users/bulk-action";
import {
  ANT_LOCALE,
  CLIENT_CETEGORIES,
  FILTER_MAPPING_VALUE,
  PLANS,
  SORTER_MAPPING_VALUE,
} from "../../../constants/app-constants";
import {
  BulkActionType,
  ClientCategory,
  ClientStatus,
  PlanType,
  UserPlanStatus,
  ZClientConnectStatus
} from "../../../constants/app.enums";
import {
  FunctionEnum,
  PermissionEnum,
} from "../../../constants/permission.constants";
import { ROUTE_PATHS } from "../../../constants/router.constants";
import useGetAdminStats from "../../../hooks/useGetAdminStats";
import useGetClients from "../../../hooks/useGetClients";
import useHasPermission from "../../../hooks/useHasPermission";
import useZoho from "../../../hooks/useZoho";
import {
  ClientListModel,
  ClientUserModel,
  CreateSiteRequestModel,
  UpdateClientRequestModel,
} from "../../../models/client.model";
import { UserPlanModel } from "../../../models/user";
import { UserGroupModel } from "../../../models/user-group.model";
import { adminUseGroupsSelector } from "../../../states/admin";
import { clientSitesState, integrationState, listClientState } from "../../../states/clientSites";
import { pageTitleState } from "../../../states/common";
import { CommonUtils } from "../../../utils/common";
import DateUtils from "../../../utils/date.utils";
import i18n from "../../../utils/i18n";
import searchDebounce from "../../../utils/searchDebounce";
import "./index.scss";

export enum TabKeys {
  OverView = "OverView",
  Integration = "Integration",
  Groups = "Groups",
  Services = "Services",
  Activities = "Activities",
}

export const getStatusClient = (status?: string, isDisabled?: boolean, isPendingDeletion?: boolean, isCanceledPlan?: boolean) => {

  if (isDisabled) {
    return <Badge status="default" text="Disabled" />;
  }

  if (isPendingDeletion) {
    return <Badge status="error" text={i18n.t("clientSites.pendingDeletion") as string} />;
  }

  if (isCanceledPlan) {
    return <Badge status="default" text={i18n.t("clientSites.canceled") as string} />
  }

  switch (status) {
    case ClientStatus.Disabled:
      return <Badge status="default" text="Disabled" />;
    case ClientStatus.Error:
      return <Badge status="error" text="Error" />;
    case ClientStatus.Active:
      return <Badge status="success" text="Active" />;
    default:
      return "";
  }
};

export const getUserPlan = (userPlan?: UserPlanModel) => {
  if (!userPlan) {
    return <div className="client-sites-page__plan none" >{i18n.t("clientSites.none") as string}</div>
  }
  else {
    switch (userPlan?.planType) {
      case PlanType.Free:
        return <div className="client-sites-page__plan free-plan" >{i18n.t("clientSites.freePlan") as string}</div>;
      case PlanType.Trial:
        return <div className="client-sites-page__plan free-trial" >{i18n.t("clientSites.freeTrial") as string}</div>;
      case PlanType.Premium:
        return <div className="client-sites-page__plan premium" >{i18n.t("clientSites.premium") as string}</div>;
      default:
        return "";
    }
  }
};

function ClientSitesPage() {
  const { t } = useTranslation();
  const { getClients } = useGetClients();
  const { hasPermission } = useHasPermission();
  const { zohoClients } = useZoho();
  const [searchParams] = useSearchParams();
  const [formOverView] = Form.useForm();

  const [showModal, setShowModal] = useState(false);
  const [activeTab, setActiveTab] = useState(TabKeys.OverView);
  const [state, setState] = useRecoilState(clientSitesState);

  const [listState, setListState] = useRecoilState(listClientState);
  const {
    loading,
    data: { records: data, totalRecords },
    query: { pageSize, pageIndex },
  } = listState;

  const [submitLoading, setSubmitLoading] = useState(false);
  const setPageTile = useSetRecoilState(pageTitleState);
  const [bulkAction, setBulkAction] = useState<BulkActionType | null>(null);
  const [selected, setSelected] = useState<ClientListModel[]>([]);
  const [searchInput, setSearchInput] = useState("");
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const { getAdminStats } = useGetAdminStats();
  const [groups, setGroups] = useRecoilState(adminUseGroupsSelector);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const integrationValues =
    useRecoilValue(integrationState);

  const editSite = useCallback((client: ClientListModel) => {
    const userInfo: any = client.user || {};
    setShowModal(true);

    const overViewFormValues: ClientUserModel = {
      enabled: client.clientStatus !== ClientStatus.Disabled ? true : false,
      companyName: client.companyName,
      email: userInfo.email,
      firstName: userInfo.firstName,
      businessPhone: client.businessPhone,
      lastName: userInfo.lastName,
      website: client.websiteUrl,
      category: client.category || ClientCategory.Uncategorised,
      state: client.state,
      streetAddress: client.streetAddress,
      streetAddressLine2: client.streetAddressLine2,
      zipCode: client.zipCode,
      city: client.city,
      timeZone: client.timeZone,
      country: client.country,
    };

    setState({
      ...state,
      clientSelected: client,
      overViewFormValues: overViewFormValues,
    });
  }, []);

  const getSelectedClients = () => {
    return selected.map((client) => client.id);
  }

  const submit = useCallback(() => {
    switch (activeTab) {
      case TabKeys.OverView:
        updateClient();
        break;
      default:
        break;
    }
  }, [state.clientSelected, state.overViewFormValues]);

  async function updateClient() {
    if (state.clientSelected && state.overViewFormValues) {
      setSubmitLoading(true);
      const model = state.overViewFormValues;

      const requestModel: UpdateClientRequestModel = {
        companyName: model.companyName,
        email: model.email,
        firstName: model.firstName,
        businessPhone: model.businessPhone,
        lastName: model.lastName,
        website: model.website,
        category: model.category,
        state: model.state,
        streetAddress: model.streetAddress,
        streetAddressLine2: model.streetAddressLine2,
        zipCode: model.zipCode,
        city: model.city,
        isDisabled: !model.enabled,
        timeZone: model.timeZone,
        country: model.country,
      };

      try {
        await formOverView.validateFields();
        await AuthApi.adminUpdateClient(state.clientSelected.id, requestModel);
        showNotification("success", t("common.changeSaveSuccess"));
        setShowModal(false);
        getClients();
      } catch { }
      setSubmitLoading(false);
    }
  }

  async function deleteClients() {
    try {
      await AuthApi.deleteClients({
        clientIds: selected.map((client) => client.id),
      });
      showNotification("delete", t("clientSites.deleteClient"));
      await getClients();
      getAdminStats();
      setBulkAction(null);
    } catch { }
  }

  async function moveClients(group: UserGroupModel) {
    try {
      await AuthApi.moveUsers({
        userIds: selected.map((client: any) => client.user.id),
        groupIds: [group.id],
      });
      showNotification(
        "success",
        t("clientSites.moveClient", { group: group?.name })
      );
      await getClients();
      setBulkAction(null);
    } catch { }
  }

  async function createSite(
    model: CreateSiteRequestModel,
    onCancleForm?: () => void
  ) {
    try {
      await AuthApi.createClient(model);
      showNotification(
        "success",
        t("clientSites.addNewSiteSuccess", { name: model.companyName }),
        t("clientSites.addNewSiteSuccessDescription")
      );
      getClients();
      getAdminStats();
      onCancleForm && onCancleForm();
      setOpenCreateModal(false);
    } catch (error: any) {
      const dataError = error?.response?.data;
      if (dataError?.errors === "The Business Email is already in use.") {
        if (dataError?.user?.id) {
          createSiteAlreadyEmail(model, dataError?.user?.id, () => {
            showNotification(
              "success",
              t("clientSites.addNewSiteSuccess", { name: model.companyName }),
              t("clientSites.addNewSiteSuccessDescription")
            );
            getClients();
            getAdminStats();
            onCancleForm && onCancleForm();
            setOpenCreateModal(false);
          });
        }
      } else {
        showNotification("error", dataError);
        return;
      }
    }
  }

  const createSiteAlreadyEmail = (
    model: CreateSiteRequestModel,
    userId: number,
    cb: () => void
  ) => {
    Modal.confirm({
      title: t("clientSites.existBusinessEmail"),
      content: <span>{t("clientSites.existBusinessEmailDescription")}</span>,
      okText: t("social.content.proceed"),
      cancelText: t("common.cancel"),
      cancelButtonProps: { type: "text" },
      icon: "",
      className: "confirm-modal exist-business-email-modal",
      centered: true,
      onOk: async () => {
        await AuthApi.adminCreateClientForUser(model, userId);
        cb();
      },
    });
  };

  function goToThisSite(client: ClientListModel) {
    AuthApi.logAccessClient(client.id, true);
    const link = CommonUtils.getAdminViewClientSiteRouter(
      ROUTE_PATHS.Home,
      client.id
    );

    window.open(link);
    // navigate(link);
  }

  async function deleteSite(client: ClientListModel) {
    await AuthApi.deleteClient(client.id);

    showNotification(
      "delete",
      t("clientSites.deleteSuccess", { param1: client.companyName })
    );

    getClients();
    getAdminStats();
  }

  async function sendInvitationEmail(client: ClientListModel) {
    if (!client.user?.email) {
      return;
    }

    try {
      setListState({ ...listState, loading: true });
      await AuthApi.sendInvitationEmail(client.user?.email);
      const newRecords = CommonUtils.deepCopy(listState.data.records);
      let updatedRecord = newRecords.find((x) => x.id === client.id);
      if (updatedRecord?.user) {
        updatedRecord.user.needSendInvitation = false;
      }

      setListState({
        ...listState,
        loading: false,
        data: { ...listState.data, records: newRecords },
      });
      showNotification(
        "success",
        t("clientSites.sendInvitaionEmailSuccess", {
          param1: client.user?.email,
        })
      );
    } catch (error) {
      setListState({ ...listState, loading: false });
    }
  }

  function confirmDelete(client: ClientListModel) {
    Modal.confirm({
      title: t("clientSites.deleteTitle"),
      content: (
        <span
          dangerouslySetInnerHTML={{
            __html: t("clientSites.deleteDescription", {
              param1: client.companyName,
            }),
          }}
        ></span>
      ),
      okText: t("common.delete"),
      cancelText: t("common.cancel"),
      cancelButtonProps: { type: "text" },
      icon: "",
      className: "confirm-modal confirm-delete-modal",
      centered: true,
      onOk: () => deleteSite(client),
    });
  }

  const getMenuItems = (client: ClientListModel) => {
    let menus = [
      {
        onClick: () => goToThisSite(client),
        label: <span>{t("clientSites.launchThisSite")}</span>,
        key: "0",
        permission: PermissionEnum.View,
      },
      {
        label: <span>{t("clientSites.editThisSite")}</span>,
        key: "1",
        onClick: () => editSite(client),
        permission: PermissionEnum.Edit,
      },
      {
        label: <span>{t("clientSites.resendInvitationEmail")}</span>,
        key: "4",
        onClick: () => sendInvitationEmail(client),
      },
      {
        type: "divider",
        key: "5",
        permission: PermissionEnum.Delete,
      },
      {
        label: (
          <Typography.Text type="danger">
            {t("clientSites.deleteThisSite")}
          </Typography.Text>
        ),
        key: "3",
        onClick: () => confirmDelete(client),
        permission: PermissionEnum.Delete,
      },
    ].filter(
      (item) =>
        !item.permission ||
        hasPermission({
          function: FunctionEnum.ClientSites,
          permission: item.permission,
        })
    );

    // RLV2-362: Disable the Resend Invitation email action after it’s done
    if (!client.user || !client.user.needSendInvitation) {
      menus = menus.filter((x) => x.key !== "4");
    }

    return menus;
  };

  const columns: ColumnsType<ClientListModel> = [
    {
      title: t("clientSites.siteId"),
      dataIndex: "id",
      sorter: true,
      render: (id, _) => id,
    },
    {
      title: t("clientSites.siteName"),
      dataIndex: "companyName",
      sorter: true,
    },
    {
      title: t("clientSites.industry"),
      dataIndex: "category",
      sorter: true,
      render: (category) => {
        return <>{CLIENT_CETEGORIES.find(x => x.value === category)?.name}</>;
      },
    },
    {
      title: t("clientSites.plan"),
      dataIndex: "plan",
      sorter: true,
      render: (_, client) => {
        return getUserPlan(client.userPlan)
      },
    },
    {
      title: t("clientSites.status"),
      dataIndex: "clientStatus",
      sorter: true,
      render: (_, client) => {
        const isCanceledPlan = client?.userPlan?.status === UserPlanStatus.Canceled || client?.userPlan?.status === UserPlanStatus.PendingCancel;
        return getStatusClient(client.clientStatus, false, client?.user?.isPendingDeletion, isCanceledPlan);
      },
    },
    {
      title: t("clientSites.dateCreated"),
      dataIndex: "createdOn",
      sorter: true,
      render: (text) => (
        <div className="table-cell">
          <p>{DateUtils.convertServerToDateViewFormat(text)}</p>
          <span>{DateUtils.convertServerToTimeViewFormat(text)}</span>
        </div>
      ),
    },
    {
      title: t("clientSites.lastlogin"),
      dataIndex: "lastLogin",
      sorter: true,
      render: (_, client) => (
        <div className="table-cell">
          {client?.lastLoginDate ? (
            <>
              <p>
                {DateUtils.convertServerToDateViewFormat(
                  client?.lastLoginDate
                )}
              </p>
              <span>
                {DateUtils.convertServerToTimeViewFormat(
                  client?.lastLoginDate
                )}
              </span>
            </>
          ) : (
            "-"
          )}
        </div>
      ),
    },
    {
      title: t("clientSites.lastLoggedInBy"),
      dataIndex: "loggedInBy",
      sorter: true,
      render: (_, client) => (
        <span>
          {`${client?.lastLoggedInBy ?? "-"
            }`}
        </span>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      render: (text, record) => (
        <div>
          <Dropdown
            menu={{ items: getMenuItems(record) }}
            trigger={["click"]}
            placement="bottomRight"
          >
            <Button className="dropdown-action-button">
              {t("clientSites.action")}
              <SVGIcons.ChevronDown />
            </Button>
          </Dropdown>
        </div>
      ),
    },
  ];

  const onChangeRow = (selectedRowKeys: any, selectedRows: any, info: any) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelected(selectedRows);
  };

  const onTableChange = async (pagination: any, filter: any, sorter: any) => {
    const { current: pageIndex, pageSize } = pagination;
    const { field, order } = sorter;
    const orderBy = SORTER_MAPPING_VALUE[order]
      ? SORTER_MAPPING_VALUE[field]
      : undefined;
    const orderDirection = SORTER_MAPPING_VALUE[order];
    await getClients({ pageIndex, pageSize, orderBy, orderDirection });
  };

  const onChangeFilter = async (value: any) => {
    const filter = Object.keys(value).reduce((result, key) => {
      return { ...result, [FILTER_MAPPING_VALUE[key]]: value[key] };
    }, {});
    await getClients({
      statuses: undefined,
      categories: undefined,
      userGroups: undefined,
      plans: undefined,
      pageIndex: 1,
      ...filter,
    });
  };

  const onChangeSearchInput = async (event: any) => {
    const keyword = event.target.value;
    setSearchInput(keyword);
    searchDebounce(keyword, () => getClients({ keyword, pageIndex: 1 }));
  };

  const itemRender = (page: any, type: any, originalElement: any) => {
    if (type === "prev") {
      return (
        <>
          <SVGIcons.ChevronLeft /> {t("clientSites.prev")}
        </>
      );
    }
    if (type === "next") {
      return (
        <>
          {t("clientSites.next")} <SVGIcons.ChevronRight />
        </>
      );
    }
    return originalElement;
  };

  const getCheckboxProps = (record: any) => {
    return {
      disabled: bulkAction === BulkActionType.MOVE && !record.user,
    };
  };

  const showTab = async (clientId: string, tab: TabKeys) => {
    const { data } = await AuthApi.getClientDetails(parseInt(clientId));
    editSite(data);
    setActiveTab(tab);
  };

  const isInvalid = useMemo(() => {
    let result = false;
    if (integrationValues !== null) {
      if (integrationValues.crIntegrations && integrationValues.crIntegrations.length) {
        result = integrationValues.crIntegrations.some(x => x.isInvalid) || result;
      }
  
      if (integrationValues.gfIntegrations && integrationValues.gfIntegrations.length) {
        result = integrationValues.gfIntegrations.some(x => x.isInvalid) || result;
      }
  
      if (integrationValues.beIntegrations && integrationValues.beIntegrations.length) {
        result = integrationValues.beIntegrations.some(x => x.isInvalid) || result;
      }
  
      if (integrationValues.gaIntegrations && integrationValues.gaIntegrations.length) {
        result = integrationValues.gaIntegrations.some(x => x.gaProperty?.isInvalid || x.gaProperty?.googleConnection?.isInvalid) || result;
      }
  
      if (integrationValues.gmbIntegrations && integrationValues.gmbIntegrations.length) {
        result = integrationValues.gmbIntegrations.some(x => x.gmbLocation?.isInvalid || x.gmbLocation?.googleConnection?.isInvalid) || result;
      }
    }

    if (zohoClients && zohoClients.length) {
      result = zohoClients.some(x => x.status === ZClientConnectStatus.Error) || result;
    }
    return result;
  }, [integrationValues, zohoClients]);

  const getUserGroups = async () => {
    try {
      const { data } = await AuthApi.getUserGroups();
      setGroups(data);
    } catch { }
  };

  useEffect(() => {
    setPageTile(t("clientSites.title"));
    getClients({
      keyword: "",
      pageIndex: 1,
    });
  }, []);

  useEffect(() => {
    if (!bulkAction) setSelected([]);
  }, [bulkAction]);

  useEffect(() => {
    const clientId = searchParams.get("clientId");
    const tab: TabKeys =
      (searchParams.get("tab") as TabKeys) || TabKeys.OverView;
    if (clientId && tab) showTab(clientId, tab);
  }, [searchParams]);

  useEffect(() => {
    // getUserGroups();
  }, []);

  const renderPlanColor = (planType: PlanType) => {
    switch (planType) {
      case PlanType.Free:
        return 'free-plan'
      case PlanType.Trial:
        return 'free-trial'
      case PlanType.Premium:
        return 'premium'
      case PlanType.None:
        return 'none'
      default:
        return ''
    }
  }

  const dataFilter = {
    status: [
      { name: "Active", value: ClientStatus.Active },
      { name: "Error", value: ClientStatus.Error },
      { name: "Disabled", value: ClientStatus.Disabled },
      { name: "Pending Deletion", value: ClientStatus.PendingDeletion },
      { name: "Canceled", value: ClientStatus.Canceled },
    ],
    plan: PLANS.map((plan) => {
      return { name: plan.name, value: plan.value, color: renderPlanColor(plan.value) };
    }),
    category: CLIENT_CETEGORIES,
  };

  const onSelectAll = () => {
    const allRowKeys = data.map((item) => item.id); 
    setSelectedRowKeys(allRowKeys);
    setSelected(data);
  };

  const onDeselectAll = () => {
    setSelectedRowKeys([]);
    setSelected([]);
  }

  return (
    <div className="client-sites-page">
      <div className="client-sites-page__tableWrapper">
        <div className="client-sites-page__tableHeader">
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={14}>
              <Row gutter={[16, 16]}>
                {hasPermission({
                  permission: PermissionEnum.Edit,
                  function: FunctionEnum.ClientSites,
                }) && (
                    <Col>
                      <Row>
                        <BulkAction
                          clientIds={getSelectedClients()}
                          onSelectAll={onSelectAll}
                          onDeselectAll={onDeselectAll}
                          typeBulkAction="clientSites"
                          bulkAction={bulkAction}
                          onSetBulkAction={setBulkAction}
                          amount={selected.length}
                          onDeleteAction={deleteClients}
                          onMoveAction={moveClients}
                          // total={totalRecords}
                          total={pageSize}
                        />
                      </Row>
                    </Col>
                  )}
                <Col>
                  <Input
                    placeholder={t("common.searchPlahoder")}
                    suffix={<SVGIcons.SearchIcon />}
                    className="search-input"
                    value={searchInput}
                    onChange={onChangeSearchInput}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={10}>
              <Row
                gutter={[16, 16]}
                justify={{ xs: "space-between", sm: "end" }}
              >
                <Col>
                  <FilterList data={dataFilter} onChange={onChangeFilter} />
                </Col>

                {hasPermission({
                  function: FunctionEnum.ClientSites,
                  permission: PermissionEnum.Create,
                }) && (
                    <Col>
                      <Button
                        type="primary"
                        icon={<SVGIcons.PlusIcon />}
                        onClick={() => setOpenCreateModal(true)}
                      >
                        {t("clientSites.addNewSite")}
                      </Button>
                    </Col>
                  )}
              </Row>
            </Col>
          </Row>
        </div>

        <div className="client-sites-page__table">
          <Table
            className="main-table"
            columns={
              bulkAction
                ? columns.filter((col: any) => col.dataIndex !== "id")
                : columns
            }
            dataSource={data}
            pagination={{
              pageSize: pageSize,
              current: pageIndex,
              total: totalRecords,
              itemRender: itemRender,
            }}
            rowKey="id"
            loading={loading}
            locale={ANT_LOCALE}
            rowSelection={
              bulkAction
                ? { selectedRowKeys, onChange: onChangeRow, getCheckboxProps }
                : undefined
            }
            onChange={onTableChange}
          />
        </div>
      </div>

      <Drawer
        className="app-edit-drawer"
        title="Edit Client's Site"
        open={showModal}
        width={540}
        onClose={() => setShowModal(false)}
        maskClosable={false}
        footer={
          <div className="drawer-footer-wrapper">
            <Button type="text" onClick={() => setShowModal(false)}>
              {t("clientSites.btnClose")}
            </Button>
            <Button
              type="primary"
              onClick={submit}
              loading={submitLoading}
              disabled={activeTab !== TabKeys.OverView}
            >
              {t("clientSites.btnSave")}
            </Button>
          </div>
        }
      >
        <Tabs
          type="card"
          activeKey={activeTab}
          onChange={(key) => setActiveTab(key as TabKeys)}
          className="edit-client-site-tab"
        >
          <Tabs.TabPane
            tab={t("clientSites.tabOverview")}
            key={TabKeys.OverView}
          >
            <OverviewForm form={formOverView} />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={
              <div className="integration-tab">
                {t("clientSites.tavIntegrations")}{" "}
                {isInvalid ? <SVGIcons.AlertTriangleIcon /> : ""}
              </div>
            }
            key={TabKeys.Integration}
          >
            <IntergrationForm />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={`${t("clientSites.services")}`}
            key={TabKeys.Services}
          >
            <ServicesForm />
          </Tabs.TabPane>

          {/* <Tabs.TabPane
            tab={`${t("clientSites.activities")}`}
            key={TabKeys.Activities}
          >
            <Activities />
          </Tabs.TabPane> */}
        </Tabs>
      </Drawer>

      <CreateSiteForm
        open={openCreateModal}
        close={() => setOpenCreateModal(false)}
        onSubmit={(value, onCancleForm) => createSite(value, onCancleForm)}
      />
    </div>
  );
}

export default ClientSitesPage;
