import { Button, Col, Row, Spin, Switch } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { NotificationApi } from "../../../../../apis/notification.api";
import { ServiceType } from "../../../../../constants/app.enums";
import useAdminSite from "../../../../../hooks/useAdminSite";
import useClient from "../../../../../hooks/useClient";
import { accessServiceState } from "../../../../../states/social";
import "./index.scss";
import showNotification from "../../../../common/notification";
import { NotificationSettings } from "../../../../../models/notification.model";
import { authState } from "../../../../../states/auth";

const enum Tabs {
  ME = 1,
  TEAM_MEMBERS = 2,
}

function NotificationsTab() {
  const { t } = useTranslation();
  const { clientId, isOwner, isAdminViewSite } = useClient();
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.ME);
  const { user } = useRecoilValue(authState);

  const [originalSettings, setOriginalSettings] = useState<NotificationSettings>()


  const [settings, setSettings] = useState<NotificationSettings>({
    dbConnectionLost: false,
    dbDataSyncFailed: false,
    socialContentFailed: false,
    socialContentSuccessfully: false,
    socialRequireApproval: false,
    socialContentApproved: false,
    socialContentDeclined: false,
    socialNewComment: false,
  });

  const [submitLoading, setSubmitLoading] = useState(false);
  const [accessServices, setAccessServices] =
    useRecoilState(accessServiceState);
  const isAdmin = useAdminSite();

  const isHideSocial =
    !accessServices.data.includes(ServiceType.Social);

  const isShowRecipients = (isOwner() || isAdminViewSite) && !isHideSocial;


  const getNotificationSettings = async () => {
    if (!clientId) return;
    try {
      setLoading(true);
      if (activeTab === Tabs.ME) {
        const res = await NotificationApi.getMyNotificationSettings({
          clientId,
          isAdmin,
        });
        setSettings(res.data);
        setOriginalSettings(res.data);
      } else {
        const res = await NotificationApi.getTeamNotificationSettings({
          clientId,
        });
        setSettings(res.data);
        setOriginalSettings(res.data);
      }
    } finally {
      setLoading(false);
    }
  };

  const saveSettings = async () => {
    if (!clientId) return;
    try {
      setSubmitLoading(true);
      if (activeTab === Tabs.ME && user) {
       const res = await NotificationApi.saveMyNotificationSettings(
          clientId,
          isAdmin,
          settings
        );
        setSettings(res.data);
        setOriginalSettings(res.data);
      } else {
        const res = await NotificationApi.saveTeamNotificationSettings(clientId, settings);
        setSettings(res.data);
        setOriginalSettings(res.data);
      }
      showNotification("success", t("common.changeHaveBeenSaveSuccessfully"));
    } finally {
      setSubmitLoading(false);
    }
  };

  const onChangeTab = (tab: Tabs) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    getNotificationSettings();
  }, [clientId, activeTab]);

  return (
    <div className="notifications-tab">
      <div className="head-title">
          <span>{t("generalSettings.notificationTitle")}</span>
      </div>
      <Row gutter={[16, 16]}>
        {
          isShowRecipients && <Col xs={24} sm={8}>
            <div className="card">
              <div className="card-title">{t("generalSettings.recipients")}</div>
              <div className="recipients-desc">
                {t("generalSettings.selectUserEditNotification")}
              </div>

              <div className="recipients-tabs">
                <div
                  onClick={() => onChangeTab(Tabs.ME)}
                  className={`tab ${activeTab === Tabs.ME ? "active" : ""}`}
                >
                  {t("generalSettings.me")}
                </div>
                <div
                  onClick={() => onChangeTab(Tabs.TEAM_MEMBERS)}
                  className={`tab ${activeTab === Tabs.TEAM_MEMBERS ? "active" : ""
                    }`}
                >
                  {t("generalSettings.myTeamMembers")}
                </div>
              </div>
            </div>
          </Col>
        }

        <Col xs={24} sm={isShowRecipients ? 16 : 24}>
          <div className="card-wrapper">
            <Spin spinning={loading}>
              <div className="card">
                <div className="card-title">
                  {t("generalSettings.dashboards")}
                </div>

                <div className="switches">
                  <div>
                    <Switch
                      checked={settings.dbConnectionLost}
                      onChange={(value) =>
                        setSettings({
                          ...settings,
                          dbConnectionLost: value,
                        })
                      }
                    />
                    {t("generalSettings.aConnectionIsLost")}
                  </div>

                  <div>
                    <Switch
                      checked={settings.dbDataSyncFailed}
                      onChange={(value) =>
                        setSettings({
                          ...settings,
                          dbDataSyncFailed: value,
                        })
                      }
                    />
                    {t("generalSettings.aDataSyncFails")}
                  </div>
                </div>
              </div>
            </Spin>

            {!isHideSocial && (
              <Spin spinning={loading}>
                <div className="card">
                  <div className="card-title">
                    {t("generalSettings.notificationsSocial")}
                  </div>
                  <div className="switches">
                    <div>
                      <Switch
                        checked={settings.socialContentFailed}
                        onChange={(value) =>
                          setSettings({
                            ...settings,
                            socialContentFailed: value,
                          })
                        }
                      />
                      {t("generalSettings.aContentFailsToPublish")}
                    </div>

                    <div>
                      <Switch
                        checked={settings.socialContentSuccessfully}
                        onChange={(value) =>
                          setSettings({
                            ...settings,
                            socialContentSuccessfully: value,
                          })
                        }
                      />
                      {t("generalSettings.aContentIsPublishedSuccessfully")}
                    </div>

                    <div>
                      <Switch
                        checked={settings.socialRequireApproval}
                        onChange={(value) =>
                          setSettings({
                            ...settings,
                            socialRequireApproval: value,
                          })
                        }
                      />
                      {t("generalSettings.aContentRequiresApproval")}
                    </div>

                    <div>
                      <Switch
                        checked={settings.socialContentApproved}
                        onChange={(value) =>
                          setSettings({
                            ...settings,
                            socialContentApproved: value,
                          })
                        }
                      />
                      {t("generalSettings.aContentIsApproved")}
                    </div>

                    <div>
                      <Switch
                        checked={settings.socialContentDeclined}
                        onChange={(value) =>
                          setSettings({
                            ...settings,
                            socialContentDeclined: value,
                          })
                        }
                      />
                      {t("generalSettings.aContentIsDeclined")}
                    </div>

                    <div>
                      <Switch
                        checked={settings.socialNewComment}
                        onChange={(value) =>
                          setSettings({
                            ...settings,
                            socialNewComment: value,
                          })
                        }
                      />
                      {t("generalSettings.aContentHasANewComment")}
                    </div>
                  </div>
                </div>
              </Spin>
            )}
          </div>
        </Col>
      </Row>
      {
        isShowRecipients && <div className="notification-note">
        {t("generalSettings.notificationNote")}
      </div>
      }
      <div className="save-btn">
        <Button
          disabled={JSON.stringify(originalSettings) === JSON.stringify(settings)}
          onClick={saveSettings} type="primary" loading={submitLoading}>
          {t("common.saveChanges")}
        </Button>
      </div>
    </div>
  );
}

export default NotificationsTab;
