import { Col, Row } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { NUMBER_UTIL } from "../../../../utils/number.utils";
import { CommonUtils } from "../../../../utils/common";
import SVGIcons from "../../../icons/svgs";
import SiteTrafficChart from "../charts/site-traffic";
import "./index.scss";
import {
  dasboardDateRangeState,
  dashboardGaGeneralState,
} from "../../../../states/dashboard";
import SpinDiv from "../../../common/spin-div";
import Volatility from "../../../common/volatility";

export function renderVolatility(value: number | undefined) {
  value = value ?? 0;

  if (Math.floor(value) > 0) {
    return (
      <span className="text-success">
        {Math.floor(value)}% <SVGIcons.TrendUpIcon />
      </span>
    );
  }

  if (Math.floor(value) === 0) {
    return (
      <span className="text-warning">
        0% <SVGIcons.MinusIcon />
      </span>
    );
  }

  if (Math.floor(value) < 0) {
    return (
      <span className="text-error">
        {Math.floor(0 - value)}% <SVGIcons.TrenDownIcon />
      </span>
    );
  }
}

export enum SideTrafficChartType {
  Visitors = "Visitors",
  PageViews = "PageViews",
  AvgVisitDuration = "AvgVisitDuration",
  BounceRate = "BounceRate",
  NewVisitors = "NewVisitors",
}

export interface SideTrafficModel {
  key: SideTrafficChartType;
  label: string;
  color: string;
  value: string | number | undefined;
  volatility: number | undefined;
}

function SiteTrafic() {
  const { t } = useTranslation();
  const gaGeneralState = useRecoilValue(dashboardGaGeneralState);
  const gaGeneral = gaGeneralState.data;
  const dateRange = useRecoilValue(dasboardDateRangeState);

  const chartItems = [
    {
      key: SideTrafficChartType.Visitors,
      label: "Visitors",
      color: "#0185de",
      value: NUMBER_UTIL.convertNumericToFormattedString(
        gaGeneral?.total?.users
      ),
      volatility: gaGeneral?.volatility?.users,
    },
    {
      key: SideTrafficChartType.PageViews,
      label: "Page Views",
      color: "#9bc13c",
      volatility: gaGeneral?.volatility?.pageviews,
      value: NUMBER_UTIL.convertNumericToFormattedString(
        gaGeneral?.total?.pageviews
      ),
    },
    {
      key: SideTrafficChartType.AvgVisitDuration,
      label: "Avg. Visit Duration",
      color: "#738fbd",
      value: CommonUtils.secondsToHms(gaGeneral?.total?.avgSessionDuration),
      volatility: gaGeneral?.volatility?.avgSessionDuration,
    },
    {
      key: SideTrafficChartType.BounceRate,
      label: "Bounce Rate",
      color: "#f59f00",
      volatility: gaGeneral?.volatility?.bounceRate,
      value: gaGeneral?.total?.bounceRate
        ? `${gaGeneral.total.bounceRate.toFixed(0)}%`
        : "0%",
    },
    {
      key: SideTrafficChartType.NewVisitors,
      label: "New Visitors",
      color: "#2fb344",
      volatility: gaGeneral?.volatility?.newUsers,
      value: `${(
        ((gaGeneral?.total?.newUsers || 0) / (gaGeneral?.total?.users || 1)) *
        100
      ).toFixed(0)} %`,
    },
  ];

  const [chartSelected, setChartSelected] = useState(chartItems[0]);

  return (
    <div className="home-site-traffic">
      <Row gutter={16}>
        <Col xs={24}>
          <div className="rl-card">
            <p className="rl-card-title mr-bottom-24">
              <span>{t("dashboard.siteTraffic")}</span>
              <span>{dateRange.totalDays}d</span>
            </p>

            <SpinDiv loading={gaGeneralState.loading} style={{ height: 362 }}>
              <>
                <SiteTrafficChart
                  chartId="siteTrafficChart"
                  chartData={chartSelected}
                />

                <Row gutter={16} className="home-site-traffic__itemWrapper">
                  {chartItems.map((item, index) => (
                    <Col
                      xs={24}
                      sm={12}
                      key={index}
                      className="home-site-traffic__item"
                    >
                      <div
                        className={`home-site-traffic__content${
                          item.key === chartSelected.key ? " active" : ""
                        }`}
                        style={{ borderColor: item.color }}
                        onClick={() => setChartSelected(item)}
                      >
                        <div>
                          <p className="home-site-traffic__content__title">
                            <span
                              className="home-site-traffic__content__legend"
                              style={{ backgroundColor: item.color }}
                            ></span>
                            <span>{item.label}</span>
                          </p>
                          <p className="home-site-traffic__content__value">
                            {item.value}
                          </p>
                        </div>

                        <div className="home-site-traffic__content__volatility">
                          <Volatility value={item.volatility} />
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </>
            </SpinDiv>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default SiteTrafic;
