import { Button, Drawer, Form, Input, Select, Spin, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReportCardApi } from "../../../../apis/reportCard.api";
import {
  VALIDATION_MESSAGE_CONFIG,
} from "../../../../constants/app-constants";
import { ZohoReportType } from "../../../../constants/app.enums";
import useClient from "../../../../hooks/useClient";
import useZoho from "../../../../hooks/useZoho";
import { Owner, OwnerModel, ZohoOrgs } from "../../../../models/zoho.model";
import showNotification from "../../../common/notification";
import SVGIcons from "../../../icons/svgs";
import IntegrationAlert from "../../client-sites/intergration-form/alert";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import { useRecoilState } from "recoil";
import { reportCardState } from "../../../../states/zoho";
import { ZohoApi } from "../../../../apis/zoho.api";
import useAdminSite from "../../../../hooks/useAdminSite";

type Props = {
  setOpen: (value: boolean) => void;
  open: boolean;
  type?: ZohoReportType;
  isNew: boolean;
};

const ReportCardForm = (props: Props) => {
  const { setOpen, open, type, isNew } = props;
  const [initialValues, setInitialValues] = useState({});
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [reports, setReports] = useRecoilState(reportCardState);
  const [isDisabledFields, setIsDisabledFields] = useState(false);

  const isAdmin = useAdminSite();

  const handleFormChange = () => {
    const selectedZohoClient = form.getFieldValue("zohoClientId");
    const isClientInAdminRole = usersInfo.data?.users.find(x => x.zohoClientId === selectedZohoClient)?.isAdminConnect;

    if(!isAdmin && isClientInAdminRole) {
      setIsDisabledFields(true);
    }
    else {
      setIsDisabledFields(false);
    }

  }

  const { t } = useTranslation();
  const {
    connectZoho,
    status,
    usersInfo,
    checkConnectedZoho,
    checkConnectedLoading,
    getOrgOwners,
    connectZohoLoading,
    fetchReportByType,
    ownersLoading,
    orgOwners,
    getCardIdByType,
    getCardDataByType,
    resetStatus,
    getCardTitleByType,
    setOrgOwners
  } = useZoho();
  const [form] = Form.useForm();
  const [searchOwner, setSearchOwner] = useState("");
  const [nameValue, setNameValue] = useState("");
  const { clientId } = useClient();
  const [syncUsersLoading, setSyncUsersLoading] = useState(false);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [orgs, setOrgs] = useState<ZohoOrgs[]>([]);

  function onSearchOwner(e: any) {
    setSearchOwner(e.target.value);
  }
  function onBlurSearch() {
    setSearchOwner("");
  }
  function displayUser(option: OwnerModel) {
    const { fullName, email } = option;
    const indexSearch = fullName
      .toLocaleLowerCase()
      .indexOf(searchOwner.toLocaleLowerCase());
    return (
      <div className="select-users__display">
        <div className="select-users__display-name">
          {fullName.substring(0, indexSearch)}
          <span className="select-users__display-highlight">
            {fullName.substring(indexSearch, indexSearch + searchOwner.length)}
          </span>
          {fullName.substring(indexSearch + searchOwner.length)}{" "}
          <span className="select-users__display-email">{email}</span>
        </div>
      </div>
    );
  }

  function testConnection() {
    form
      .validateFields().then(async () => {
        await checkConnectedZoho(form.getFieldValue("zohoClientId"));
      })
  }


  const onSyncUsers = async () => {
    const orgId = form.getFieldValue("orgId");
    if (!clientId || !orgId) return;

    try {
      setSyncUsersLoading(true);
      await ZohoApi.syncUsers(clientId, orgId);
      await getOrgOwners(orgId);

    } finally {
      setSyncUsersLoading(false);
    }
  }

  async function submit() {
    if (type === undefined) return;
    form
      .validateFields()
      .then(async (values) => {
        try {
          if (!clientId) return;
          setSubmitLoading(true);
          const submitValues = {
            ...values,
            type,
          };

          let cardName

          const id = getCardIdByType(type);
          if (id) {
            const { data } = await ReportCardApi.updateReportCard(
              id,
              submitValues
            );
            cardName = data.name
            fetchReportByType(type, data.id);
            if (reports.data) {
              const index = reports.data.findIndex((item) => item.id === id);
              if (index !== -1) {
                const updatedData = reports.data.map((item, i) =>
                  i === index ? data : item
                );
                setReports({
                  ...reports,
                  data: updatedData,
                });
              }
            }

          } else {
            const { data } = await ReportCardApi.createReportCard(
              clientId,
              submitValues
            );
            cardName = data.name
            if (reports.data) {
              setReports({
                ...reports,
                data: [...reports.data, data],
              })
            }
            else {
              setReports({
                ...reports,
                data: [data],
              })
            }
            await fetchReportByType(type, data.id);
          }
          onCloseDrawer();
          showNotification(
            "success",
            t("salesAnalytics.saveSuccess", { title: cardName })
          );
        } finally {
          setSubmitLoading(false);
        }
      })
      .catch((error) => console.log("e", error));
  }

  const tagRender = (props: CustomTagProps) => {
    const { value, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    const owner = orgOwners.find((owner) => owner.id === value);
    return (
      <div onMouseDown={onPreventMouseDown} className="user-tag">
        <span className="user-tag-label" >{owner && owner.fullName}</span>
        {
          !isDisabledFields &&  <span onClick={onClose}>
          <SVGIcons.CloseIcon />
        </span>
        }
      </div>
    );
  };

  const onSetDefaultOwners = async (orgId: number, owners: Owner[]) => {
    form.setFieldsValue({ ownerIds: [] });
    try {
      await getOrgOwners(orgId);
      form.setFieldsValue({ ownerIds: owners.map((item) => item.id) });
    } catch (error) {
      form.setFieldsValue({ ownerIds: [] });
    }
  };

  const onCloseDrawer = () => {
    setOpen(false);
    resetStatus()
  };

  const onChangeAccount = (value: number) => {
    setOrgs([]);
    form.setFieldValue("orgId", null);
    form.setFieldValue("ownerIds", undefined);
    setOrgOwners([]);
    const account = usersInfo.data?.users?.find(x => x.zohoClientId === value);

    if(account) {
      const accountOrgs = account?.orgs;
      if(accountOrgs && accountOrgs?.length > 0) {
        setOrgs(accountOrgs);
      }
    }
  }

  useEffect(() => {
    if (type === undefined) return;
    const cardData = getCardDataByType(type);
    setIsFormChanged(false);
    if (isNew) {
      const cardName = getCardTitleByType(type);
      if (cardName) {
        const values = {
          zohoClientId: undefined,
          orgId: undefined,
          name: cardName,
          ownerIds: undefined,
        }
        setNameValue(cardName);
        form.setFieldsValue(values);
        setInitialValues(values);
      }
    }
    else {
      if (cardData) {
        if (cardData.zohoClientId) {
          onChangeAccount(cardData.zohoClientId);
        }
        form.setFieldsValue(cardData);
        setInitialValues({
          zohoClientId: cardData.zohoClientId,
          orgId: cardData.orgId,
          name: cardData.name,
          ownerIds: cardData?.owners?.map((item) => item.id),
        });
        onSetDefaultOwners(cardData.orgId, cardData?.owners);
        setNameValue(cardData.name);
        handleFormChange();
      } else {
        form.resetFields();
      }
    }
  }, [type, isNew, open]);


  return (
    <Drawer
      destroyOnClose
      className="app-edit-drawer"
      title={t("salesAnalytics.editCard")}
      open={open}
      width={540}
      onClose={onCloseDrawer}
      maskClosable={false}
      footer={
        <div className="drawer-footer-wrapper">
          <Button type="text" onClick={onCloseDrawer}>
            {t("clientSites.btnClose")}
          </Button>
          <Button
            disabled={!isFormChanged}
            type="primary" onClick={submit} loading={submitLoading}>
            {t("clientSites.btnSave")}
          </Button>
        </div>
      }
    >
      <Form
        name="createUserForm"
        layout="vertical"
        validateMessages={VALIDATION_MESSAGE_CONFIG}
        onFinish={submit}
        form={form}
        onFieldsChange={() => {
          const values = form.getFieldsValue();
          setIsFormChanged(JSON.stringify(values) !== JSON.stringify(initialValues));
          handleFormChange();
        }}
      >
        <div className="connection-alert">
          <IntegrationAlert status={status}
            onReAuthenticate={connectZoho}
            reAuthenticateLoading={connectZohoLoading}
          />
        </div>
        <Form.Item label={t("salesAnalytics.account")} name="zohoClientId">
          <Select
            onChange={onChangeAccount}
            dropdownRender={(menu) => (
              <>
                {menu}

                <div
                  onClick={() => !connectZohoLoading && connectZoho()}
                  className="connect-account"
                >
                  {connectZohoLoading ? (
                    <Spin size="small" />
                  ) : (
                    <SVGIcons.ZohoIcon />
                  )}
                  {t("salesAnalytics.connectAccount")}
                </div>
              </>
            )}
          >
            {usersInfo?.data?.users?.map((item, index) => {
              return (
                <Select.Option disabled={
                  !isAdmin && item.isAdminConnect
                } key={index} value={item.zohoClientId}>
                  <div className="account-dropdown">
                    {item.email}
                    {/* <div className="remove">{t("salesAnalytics.remove")}</div> */}
                  </div>
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item label={t("salesAnalytics.organization")} name="orgId">
          <Select disabled={isDisabledFields} onChange={(e) => { form.setFieldValue("ownerIds", undefined); getOrgOwners(e) }}>
            {orgs.map((item, index) => {
              return (
                <Select.Option key={index} value={item.id}>
                  {item.companyName}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          className="card-title-field"
          label={
            <div className="card-title-field-label">
              <div>{t("salesAnalytics.cardTitle")}</div>
              <div className="counter">{nameValue.length || 0}/80</div>
            </div>
          }
          name="name"
          rules={[
            {
              required: true,
              message: t("salesAnalytics.cardTitleRequired"),
            },
          ]}
        >
          <Input
            disabled={isDisabledFields}
            onChange={(e) => setNameValue(e.target.value)}
            maxLength={80}
          />
        </Form.Item>

        <Form.Item
          className="assigned-users"
          name="ownerIds"
          rules={[{
            required: true,
            message: t("salesAnalytics.assignedUsersRequired"),
          }]}
          label={
            <div>
              {t("salesAnalytics.assignedUsers")}
              <Tooltip
                overlayClassName="custom-tooltip"
                title={<span>{t("salesAnalytics.assignedUsersTooltip")}</span>}
              >
                <span className="circle-question">?</span>
              </Tooltip>
            </div>
          }
        >
          <Select
            disabled={isDisabledFields}
            loading={ownersLoading}
            placeholder={t("salesAnalytics.selectSingleOrMultiple")}
            mode="multiple"
            tagRender={tagRender}
            popupClassName="select-property-ga"
            dropdownRender={(menu) => (
              <>
                <div className="search-property">
                  <Input
                    value={searchOwner}
                    suffix={<SVGIcons.SearchIcon />}
                    onChange={onSearchOwner}
                    onBlur={onBlurSearch}
                    onKeyDown={e => e.stopPropagation()}
                  />
                  <Tooltip
                    title={t("salesAnalytics.refreshLatestData")}
                    placement="topRight"
                  >
                    <Button
                      loading={syncUsersLoading}
                      onClick={onSyncUsers}
                      icon={<SVGIcons.RefreshIcon />}
                      type="default" className="refresh-btn" />
                  </Tooltip>
                </div>
                {menu}
              </>
            )}
          >
            {orgOwners
              ?.filter((item) =>
                item.fullName
                  .toLocaleLowerCase()
                  .includes(searchOwner.toLocaleLowerCase())
              )
              .map((owner) => {
                return (
                  <Select.Option key={owner.id} value={owner.id}>
                    {displayUser(owner)}
                  </Select.Option>
                );
              })}
          </Select>
        </Form.Item>

        <Button
          // disabled={isDisabledFields}
          className="sales-test-btn secondary-btn"
          type="primary"
          loading={checkConnectedLoading}
          onClick={testConnection}
        >
          {t("clientSites.testConnection")}
        </Button>
      </Form>
    </Drawer>
  );
};

export default ReportCardForm;
