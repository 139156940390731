import { Button, Divider, Form, Modal, Select, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ZohoApi } from "../../../../../apis/zoho.api";
import { VALIDATION_MESSAGE_CONFIG } from "../../../../../constants/app-constants";
import {
  IntegrationStatus,
  ShouldOpenModal,
  ZohoSyncStatus,
} from "../../../../../constants/app.enums";
import useZoho from "../../../../../hooks/useZoho";
import { GroupedClient, ZohoAdminClientInfo, ZohoClient, ZohoOrgs } from "../../../../../models/zoho.model";
import SVGIcons from "../../../../icons/svgs";
import IntegrationAlert from "../alert";
import { useRecoilState, useRecoilValue } from "recoil";
import { clientSitesState } from "../../../../../states/clientSites";
import showNotification from "../../../../common/notification";
import useClient from "../../../../../hooks/useClient";
import useAdminSite from "../../../../../hooks/useAdminSite";
import { useSearchParams } from "react-router-dom";
import { TabKeys } from "../../../../../pages/admin/client-sites";
import { clientAccessState } from "../../../../../states/zoho";

const AddZohoConnection = () => {
  const { t } = useTranslation();
  const [form] = useForm();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [testLoading, setTestLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [status, setStatus] = useState<IntegrationStatus | ZohoSyncStatus>(
    IntegrationStatus.None
  );
  const [zohoAdminClients, setZohoAdminClients] = useState<GroupedClient[]>([]);
  const [originalZohoAdminClients, setOriginalZohoAdminClients] = useState<ZohoClient[]>([]);
  const [zohoAdminOrgs, setZohoAdminOrgs] = useState<ZohoOrgs[]>([]);
  const [zohoClientId, setZohoClientId] = useState<number | null>(null);
  const [orgId, setOrgId] = useState<number | null>(null);
  const { isAdminViewSite ,clientId } = useClient();
  const isAdmin = useAdminSite();
  const [searchParams, setSearchParams] = useSearchParams();

  const [clientAccess, setClientAccess] = useRecoilState(
    clientAccessState
  );

  const { connectZoho, connectZohoLoading, checkConnectedZoho, getZohoClient, zohoClients } = useZoho();
  const { clientSelected } = useRecoilValue(clientSitesState);

  const onAddConnection = () => {
    if (zohoClients.length === 3) {
      showNotification("error", "Only 3 Zoho accounts are allowed. Please remove one account then try again.")
      return;
    }
    setOpen(true);
  };

  const geZohoClientIdByEmailAndOrgId = () => {
    const selectedAccount = zohoAdminClients.find((x) => x.zohoClientId === zohoClientId);
    if(!selectedAccount) return;
    const zohoClientIdByEmailAndOrgId = originalZohoAdminClients.find(x => x.zohoOrgId === orgId && x.email === selectedAccount.email)?.zohoClientId;
    return zohoClientIdByEmailAndOrgId;
  }

  const submit = async() => {
    try {
      let cId = clientId;
      if(isAdmin && !isAdminViewSite) {
        if(!clientSelected || !clientSelected.id) {
          return;
        }
        cId = clientSelected.id
      }
      if (!cId) return;

        const zohoClientIdByEmailAndOrgId = geZohoClientIdByEmailAndOrgId();
        if (!cId || !zohoClientIdByEmailAndOrgId) return;
        setSaveLoading(true);
        await ZohoApi.addMapZohoClient(cId, zohoClientIdByEmailAndOrgId);
        onCloseModal();
        getZohoClient(cId);
        showNotification(
          "success",
          t("salesAnalytics.zohoSuccessConnected")
        );
      } finally {
        setSaveLoading(false);
      }
  };

  const onChangeAccount = (value: number | undefined) => {
    if(!value) return;
    const selectedAccount = zohoAdminClients.find((x) => x.zohoClientId === value);
    const orgs = selectedAccount?.orgs;

    if(orgs && orgs?.length > 0) {
      setZohoAdminOrgs(orgs);
      if(clientAccess) {
        const { zohoOrgId } = clientAccess;
        if(zohoOrgId) {
          form.setFieldsValue({
            zohoClientId: value,
            orgId: zohoOrgId
          });
          setOrgId(zohoOrgId);
        }

        setClientAccess(undefined);
      }
      else {
        form.setFieldValue("orgId", null);
        setOrgId(null);
      }

      setZohoClientId(value);
    }
  };

  const onTestConnection = () => {
    form.validateFields().then(async () => {
      try {
        const zohoClientIdByEmailAndOrgId = geZohoClientIdByEmailAndOrgId();

        if (!zohoClientIdByEmailAndOrgId) return;
        setTestLoading(true);
        await checkConnectedZoho(zohoClientIdByEmailAndOrgId);
        setStatus(IntegrationStatus.Success);
      } catch (error) {
        setStatus(IntegrationStatus.Error);
      }
      setTestLoading(false);

    });
  };

  const getZohoClients = (data: ZohoAdminClientInfo): GroupedClient[] => {
    if (!data) return [];
  
    const groupedClients = data.zohoClients.reduce<Record<string, GroupedClient>>((acc, client) => {
      if (!acc[client.email]) {
        acc[client.email] = {
          ...client,
          orgs: [],
        };
      }
      const org = data.zohoOrgs.find((org) => org.id === client.zohoOrgId);
      if (org) {
        acc[client.email].orgs.push(org);
      }
      return acc;
    }, {});
  
    const result = Object.values(groupedClients);
  
    return result;
  };

  async function getAdminZohoAccount() {
    let cId = clientId;
    if(isAdmin && !isAdminViewSite) {
      if(!clientSelected || !clientSelected.id) {
        return;
      }
      cId = clientSelected.id
    }
    if (!cId) return;

    try {
      setLoading(true);
      const { data } = await ZohoApi.getAllAdminZoho(cId);
      const zohoClients = getZohoClients(data);
      if(zohoClients && zohoClients.length) {
        const filteredZohoClients = zohoClients.filter(x => !x.isConnected);
        setZohoAdminClients(filteredZohoClients);
        setOriginalZohoAdminClients(data.zohoClients.filter(x => !x.isConnected));
      }
    } finally {
      setLoading(false);
    }
  }

  const onCloseModal = () => {
    setOpen(false);
    setClientAccess(undefined);
  }

  useEffect(() => {
    if (open) {
      form.resetFields();
      getAdminZohoAccount();
    }
  }, [open]);

  useEffect(() => {
    const clientId = searchParams.get("clientId");
    const showModal = searchParams.get("showModal");
    
    const tab: TabKeys =
      (searchParams.get("tab") as TabKeys) || TabKeys.OverView;
    if (clientId && tab) {
      if(showModal === "zoho") {
        setOpen(true);
      }
    }
  }, [searchParams]);

  useEffect(() => {
    if(clientAccess && open && zohoAdminClients.length > 0) {
      const { zohoClientId } = clientAccess;
       onChangeAccount(zohoClientId);
    }
  }, [clientAccess, open, zohoAdminClients]);
  


  return (
    <div className="add-zoho-connection">
     
      <Button onClick={onAddConnection}>
        <SVGIcons.PlusIcon />
        <span className="mr-right-4">{t("clientSites.addConnection")}</span>
      </Button>
      <Modal
        title="Add Connection - Zoho CRM"
        centered
        open={open}
        onCancel={onCloseModal}
        footer={null}
        className="custom-create-modal"
      >
        <Form
          name="addZohoConnection"
          layout="vertical"
          validateMessages={VALIDATION_MESSAGE_CONFIG}
          onFinish={submit}
          form={form}
        >
          <div className="connection-alert">
            <IntegrationAlert status={status} />
          </div>
          <Form.Item
            label={t("salesAnalytics.account")}
            name="zohoClientId"
            rules={[
              {
                required: true,
              }
            ]}
          >
            <Select
              onChange={onChangeAccount}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <div
                    onClick={() => !connectZohoLoading && connectZoho(false, ShouldOpenModal.Zoho)}
                    className="connect-account"
                  >
                    {connectZohoLoading ? (
                      <Spin size="small" />
                    ) : (
                      <SVGIcons.ZohoIcon />
                    )}
                    {t("salesAnalytics.connectAccount")}
                  </div>
                </>
              )}
              loading={loading}
            >
              {zohoAdminClients?.map((item, index) => {
                return (
                  <Select.Option key={index} value={item.zohoClientId}>
                    <div className="account-dropdown">{item.email}</div>
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>


          <Form.Item
            label={t("salesAnalytics.organization")}
            name="orgId"
            rules={[
              {
                required: true,
              }
            ]}
          >
            <Select
              disabled={!zohoClientId}
              onChange={(value)=> setOrgId(value)}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <div
                    onClick={() => !connectZohoLoading && connectZoho(false, ShouldOpenModal.Zoho)}
                    className="connect-account"
                  >
                    {connectZohoLoading ? (
                      <Spin size="small" />
                    ) : (
                      <span className="add-org-icon" >
                        <SVGIcons.PlusIcon />
                      </span>
                    )}
                    {t("salesAnalytics.connectOrganization")}
                  </div>
                </>
              )}
              loading={loading}
            >
              {zohoAdminOrgs?.map((item, index) => {
                return (
                  <Select.Option key={index} value={item.id}>
                    <div className="account-dropdown">{item.companyName}</div>
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          <div className={`group-btn-integration`}>
            <Button
              className="test-btn secondary-btn"
              type="primary"
              loading={testLoading}
              onClick={onTestConnection}
              disabled={!zohoClientId || !orgId}
            >
              {t("clientSites.testConnection")}
            </Button>
            <Button
              htmlType="submit"
              className="save-btn primary-btn"
              type="primary"
              loading={saveLoading}
              disabled={!zohoClientId || !orgId}
            >
              {t("clientSites.addConnection")}
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default AddZohoConnection;
