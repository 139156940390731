import { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { ReportCardApi } from "../apis/reportCard.api";
import { ZohoApi } from "../apis/zoho.api";
import showNotification from "../components/common/notification";
import { ZohoCardTypes, ZohoRedirectUrl } from "../constants/app-constants";
import { IntegrationStatus, ShouldOpenModal, ZClientConnectStatus, ZohoReportType, ZohoSyncStatus } from "../constants/app.enums";
import { GroupedUser, OwnerModel, SalesAnalyticRequestModel, ZohoUserInfo, ZohoUsers } from "../models/zoho.model";
import { connectedState, reportCardState, saleAnalyticsBookingCountState, saleAnalyticsCallCountState, saleAnalyticsCallStatusState, saleAnalyticsConnectedBookingDateState, saleAnalyticsConnectionRateState, saleAnalyticsDateRangeState, saleAnalyticsTotalCallsState, saleAnalyticsTotalDemoBookedState, saleAnalyticsTotalLeadWorkedState, saleAnalyticZohoState, usersInfoState, zohoClientsState } from "../states/zoho";
import useClient from "./useClient";
import useAdminSite from "./useAdminSite";
import { clientSitesState } from "../states/clientSites";
import { useLocation } from "react-router-dom";
import { ROUTE_PATHS } from "../constants/router.constants";

type ZohoReportData = {
  detail: {
    reportItem?: any;
    [key: string]: any;
  };
};

interface ReportState<T> {
  loading: boolean;
  data: T | undefined;
}


export const ChartMockupData = {
  ConnectionRate: {
    categories: ["User", "User", "User", "User", "User"],
    data: [
      {
        name: "None",
        data: [45, 65, 60, 20, 35],
      },
      {
        name: "Connected",
        data: [55, 25, 20, 80, 65],
      },
      {
        name: "Not Connected",
        data: [0, 10, 25, 0, 0],
      },
    ],
    emptyData: [
      {
        name: "None",
        data: [0, 0, 0, 0, 0],
      },
      {
        name: "Connected",
        data: [0, 0, 0, 0, 0]
      },
      {
        name: "Not Connected",
        data: [0, 0, 0, 0, 0]
      },
    ],
  },
  ConnectedBookingDate: {
    categories: ["User", "User", "User", "User", "User"],
    data: [
      {
        name: "None",
        data: [20, 10, 50, 10, 0],
      },
      {
        name: "True",
        data: [80, 90, 50, 90, 100],
      },
    ],
    emptyData: [
      {
        name: "None",
        data: [0, 0, 0, 0, 0],
      },
      {
        name: "True",
        data: [0, 0, 0, 0, 0],
      },
    ]
  },
  BookingCount: [
    { label: "User", value: 3 },
    { label: "User", value: 7 },
    { label: "User", value: 2 },
    { label: "User", value: 5 },
    { label: "User", value: 3 },
  ],
  BookingCountEmpty: [
    { label: "User", value: 0 },
    { label: "User", value: 0 },
    { label: "User", value: 0 },
    { label: "User", value: 0 },
    { label: "User", value: 0 },
  ],
  CallStatus: {
    categories: ["User", "User", "User", "User", "User"],
    data: [
      {
        name: "None",
        data: [50, 35, 0, 0, 0],
      },
      {
        name: "Conversation",
        data: [0, 0, 0, 20, 0],
      },
      {
        name: "Dead Line",
        data: [0, 0, 65, 0, 0],
      },
      {
        name: "Do Not Call",
        data: [50, 65, 25, 0, 0],
      },
      {
        name: "Hung Up",
        data: [0, 0, 10, 20, 0],
      },
      {
        name: "No Answer",
        data: [0, 0, 10, 10, 0],
      },
      {
        name: "Not Interested",
        data: [0, 0, 0, 0, 100],
      },
      {
        name: "Voice Mail",
        data: [0, 0, 0, 50, 0],
      },
      {
        name: "Wrong ICP",
        data: [0, 0, 0, 0, 0],
      },
      {
        name: "Wrong Number",
        data: [0, 0, 0, 0, 0],
      },
    ],
    emptyData: [
      {
        name: "None",
        data: [0, 0, 0, 0, 0],
      },
      {
        name: "Conversation",
        data: [0, 0, 0, 0, 0],
      },
      {
        name: "Dead Line",
        data: [0, 0, 0, 0, 0],
      },
      {
        name: "Do Not Call",
        data: [0, 0, 0, 0, 0],
      },
      {
        name: "Hung Up",
        data: [0, 0, 0, 0, 0],
      },
      {
        name: "No Answer",
        data: [0, 0, 0, 0, 0],
      },
      {
        name: "Not Interested",
        data: [0, 0, 0, 0, 0],
      },
      {
        name: "Voice Mail",
        data: [0, 0, 0, 0, 0],
      },
      {
        name: "Wrong ICP",
        data: [0, 0, 0, 0, 0],
      },
      {
        name: "Wrong Number",
        data: [0, 0, 0, 0, 0],
      },
    ]

  },
  CallCount: [
    { label: "User", value: 7, },
    { label: "User", value: 5 },
    { label: "User", value: 2 },
    { label: "User", value: 4 },
    { label: "User", value: 3 },
  ],

  CallCountEmpty: [
    { label: "User", value: 0 },
    { label: "User", value: 0 },
    { label: "User", value: 0 },
    { label: "User", value: 0 },
    { label: "User", value: 0 },
  ],

  CardSum: {
    currentValue: 999,
    previousValue: 101,
  }
}

function useZoho() {
  const { pathname } = useLocation();
  const [usersInfo, setUsersInfo] = useRecoilState(usersInfoState);
  const [zohoClients, setZohoClients] = useRecoilState(zohoClientsState);
  const [reports, setReports] = useRecoilState(reportCardState);

  const { client } = useClient();
  const [ownersLoading, setOwnersLoading] = useState(false);
  const [usersInfoLoading, setUsersInfoLoading] = useState(false);
  const [connectZohoLoading, setConnectZohoLoading] = useState(false);
  const [checkConnectedLoading, setCheckConnectedLoading] = useState(false);
  const [reportsLoading, setReportsLoading] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const [orgOwners, setOrgOwners] = useState<OwnerModel[]>([]);
  const [status, setStatus] = useState<IntegrationStatus | ZohoSyncStatus>(IntegrationStatus.None);

  const [zohoConnected, setZohoConnected] = useRecoilState(connectedState);

  const [totalLeadWorked, setTotalLeadWorked] = useRecoilState(
    saleAnalyticsTotalLeadWorkedState
  );

  const dateRange = useRecoilValue(saleAnalyticsDateRangeState);
  const { isAdminViewSite ,clientId } = useClient();
  const isAdmin = useAdminSite();


  const [callCount, setCallCount] = useRecoilState(saleAnalyticsCallCountState);
  const [callStatus, setCallStatus] = useRecoilState(saleAnalyticsCallStatusState);
  const [totalCalls, setTotalCalls] = useRecoilState(saleAnalyticsTotalCallsState);
  const [totalDemoBook, setTotalDemoBooked] = useRecoilState(
    saleAnalyticsTotalDemoBookedState
  );

  const [zohoState, setZohoState] =
    useRecoilState(saleAnalyticZohoState);

  const [bookingCount, setBookingCount] = useRecoilState(saleAnalyticsBookingCountState);
  const [connectedBookingDate, setConnectedBookingDate] = useRecoilState(
    saleAnalyticsConnectedBookingDateState
  );

  const [connectionRate, setConnectionRate] = useRecoilState(saleAnalyticsConnectionRateState);
  const {clientSelected} = useRecoilValue(clientSitesState);


  async function getUsersInfo() {
    if (!client?.id) return;

    try {
      setUsersInfoLoading(true);
      const { data } = await ZohoApi.getAllUsersInfo(client?.id, isAdmin);
      setUsersInfo({
        ...usersInfo,
        data: {
          users: getUsers(data),
          orgs: data.orgs,
        },
      });

      if(data.orgs?.length === 0 && data.users?.length === 0) {
        setZohoConnected({
          isConnected: false,
        });
      }
      else {
        setZohoConnected({
          isConnected: true,
        });
      }

      await getReports();
    } finally {
      setUsersInfoLoading(false);
      setIsFetched(true);
    }
  }

  const getUsers = (data: ZohoUserInfo | undefined): GroupedUser[] => {
    if (!data) return [];
  
    const groupedUsers = data.users.reduce<Record<string, GroupedUser>>((acc, client) => {
      if (!acc[client.email]) {
        acc[client.email] = {
          ...client,
          orgs: [],
        };
      }
      const org = data.orgs.find((org) => org.id === client.zohoOrgId);
      if (org) {
        acc[client.email].orgs.push(org);
      }
      return acc;
    }, {});
  
    const result = Object.values(groupedUsers);
  
    return result;
  };

  async function getZohoClient(clientId: number) {
    if (!clientId) return;

    try {
      setUsersInfoLoading(true);
      const { data } = await ZohoApi.getAllZohoClient(clientId);
      data.map(x=> {
        if(x.statusMessage === ZohoSyncStatus.ZohoSynced) {
          x.statusMessage = ZohoSyncStatus.None;
        }
        return x;
      })
      setZohoClients(data ?? []);
    } finally {
      setUsersInfoLoading(false);
    }
  }

  async function getOrgOwners(orgId: number) {
    try {
      setOwnersLoading(true);
      const res = await ZohoApi.getOrgOwners(orgId);
      setOrgOwners(res.data);
    } finally {
      setOwnersLoading(false);
    }
  }

  async function getReports() {
    if (!client?.id) return;

    try {
      resetStates();
      setReports({ loading: true, data: undefined });
      setReportsLoading(true);
      const { data } = await ReportCardApi.getReportCard(client?.id);
      setReports({
        ...reports,
        data,
      });
      setZohoState({
        ...zohoState,
        triggerReloadReports: zohoState.triggerReloadReports + 1,
      });
    }

    catch (error) {
      resetStates();
      setReports({ loading: true, data: undefined });
    }

    finally {
      setReportsLoading(false);
    }
  }

  const getCardIdByType = (type: ZohoReportType) => {
    if (reports.data && reports?.data?.length > 0) {
      return reports.data?.find((report) => report.type === type)?.id;
    }
    return null;
  };

  const getCardDataByType = (type: ZohoReportType) => {
    if (reports.data && reports.data?.length > 0) {
      return reports.data?.find((report) => report.type === type);
    }
    return null;
  };

  const connectZoho = async (isReAuthen = false, shouldOpenModal?: ShouldOpenModal ) => {
    try {
      if (!isReAuthen && zohoClients.length === 3) {
        showNotification("error", "Only 3 Zoho accounts are allowed. Please remove one account then try again.")
        return;
      }

      setConnectZohoLoading(true);
      let cId = clientId;
      if(isAdmin && !isAdminViewSite) {
        if(!clientSelected || !clientSelected.id) {
          return;
        }
        cId = clientSelected.id
      }

      let customRedirect = "";

      if(shouldOpenModal === ShouldOpenModal.Zoho) {
        customRedirect = ZohoRedirectUrl.AdminEditSiteShowModal;
      } else if(pathname.includes(ROUTE_PATHS.ClientSite)) {
        customRedirect = ZohoRedirectUrl.AdminEditSite
      } else if(pathname.includes(ROUTE_PATHS.GeneralSettings)) {
        customRedirect = isAdminViewSite ? ZohoRedirectUrl.AdminViewGeneralSettings : ZohoRedirectUrl.GeneralSettings;
      } else if(pathname.includes(ROUTE_PATHS.SalesAnalytics)) {
        customRedirect = isAdminViewSite ? ZohoRedirectUrl.AdminViewSaleAnalytic : ZohoRedirectUrl.SaleAnalytic;
      }

      if(!customRedirect || !cId) {
        return;
      }

      if(isReAuthen) {
        customRedirect = `${customRedirect}_${ZohoRedirectUrl.ReAuthenticate}`;
      }

      const { data } = await ZohoApi.getConnectUrl(isAdmin, cId, customRedirect);
      if (data) {
        window.location.href = data;
      }

    } finally {
      setConnectZohoLoading(false);
    }
  }

  const checkConnectedZoho = async (zohoClientId: number) => {
    try {
      setCheckConnectedLoading(true);
      await ZohoApi.checkConnectedZoho(zohoClientId);
      setStatus(IntegrationStatus.Success);
      return true;
    }
    catch (error) {
      setStatus(IntegrationStatus.ConnectionZohoLostError);
      return false
    }
    finally {
      setCheckConnectedLoading(false);
    }
  }

  async function getZohoReport<T>(
    reportType: ZohoReportType,
    setState: React.Dispatch<React.SetStateAction<ReportState<T>>>,
    detailKey: keyof ZohoReportData['detail'] | null = 'detail',
    cardId?: number
  ) {
    let id = cardId ?? getCardIdByType(reportType);
    if (!id) return;
    if (dateRange.startDate && dateRange.endDate) {
      const request: SalesAnalyticRequestModel = {
        endDate: dateRange.endDate,
        startDate: dateRange.startDate,
      };
      try {
        setState({ loading: true, data: undefined });
        const { data } = await ZohoApi.getZohoReport(id, request);
        const reportData = detailKey ? data.detail[detailKey] : data.detail;
        setState({ loading: false, data: reportData as T });
      } catch (error) {
        setState({ loading: false, data: undefined });
      }
    }


  }

  async function getTotalLeadWorked(cardId?: number) {
    await getZohoReport(ZohoReportType.TOTAL_LEADS_WORKED, setTotalLeadWorked, null, cardId);
  }

  async function getCallCount(cardId?: number) {
    await getZohoReport(ZohoReportType.CALL_COUNT, setCallCount, 'reportItem', cardId);
  }

  async function getBookingCount(cardId?: number) {
    await getZohoReport(ZohoReportType.BOOKING_COUNT, setBookingCount, 'reportItem', cardId);
  }

  async function getConnectedBookingDate(cardId?: number) {
    await getZohoReport(ZohoReportType.CONNECTED_BOOKING_RATE, setConnectedBookingDate, 'reportItem', cardId);
  }

  async function getCallStatus(cardId?: number) {
    await getZohoReport(ZohoReportType.CALL_STATUS, setCallStatus, 'reportItem', cardId);
  }

  async function getTotalCalls(cardId?: number) {
    await getZohoReport(ZohoReportType.TOTAL_CALL, setTotalCalls, null, cardId);
  }

  async function getTotalDemoBooked(cardId?: number) {
    await getZohoReport(ZohoReportType.TOTAL_DEMO_BOOKED, setTotalDemoBooked, null, cardId);
  }

  async function getConnectionRate(cardId?: number) {
    await getZohoReport(ZohoReportType.CONNECTION_RATE, setConnectionRate, 'reportItem', cardId);
  }

  async function fetchReportByType(reportType: ZohoReportType, cardId: number) {
    if (reportType === ZohoReportType.TOTAL_LEADS_WORKED) {
      await getTotalLeadWorked(cardId);
    } else if (reportType === ZohoReportType.CALL_COUNT) {
      await getCallCount(cardId);
    } else if (reportType === ZohoReportType.BOOKING_COUNT) {
      await getBookingCount(cardId);
    } else if (reportType === ZohoReportType.CONNECTED_BOOKING_RATE) {
      await getConnectedBookingDate(cardId);
    } else if (reportType === ZohoReportType.CALL_STATUS) {
      await getCallStatus(cardId);
    } else if (reportType === ZohoReportType.TOTAL_CALL) {
      await getTotalCalls(cardId);
    } else if (reportType === ZohoReportType.TOTAL_DEMO_BOOKED) {
      await getTotalDemoBooked(cardId);
    } else if (reportType === ZohoReportType.CONNECTION_RATE) {
      await getConnectionRate(cardId);
    }
  }


  const fetchAllReports = () => {
    resetStates()
    if (usersInfo.data && usersInfo.data?.orgs?.length > 0 && usersInfo.data?.users?.length > 0) {
      getTotalLeadWorked();
      getCallCount();
      getBookingCount();
      getConnectedBookingDate();
      getCallStatus();
      getTotalCalls();
      getTotalDemoBooked();
      getConnectionRate();
    }
  }

  const resetStates = () => {
    setTotalDemoBooked({ loading: false, data: undefined });
    setTotalLeadWorked({ loading: false, data: undefined });
    setCallCount({ loading: false, data: undefined });
    setBookingCount({ loading: false, data: undefined });
    setConnectedBookingDate({ loading: false, data: undefined });
    setCallStatus({ loading: false, data: undefined });
    setTotalCalls({ loading: false, data: undefined });
    setConnectionRate({ loading: false, data: undefined });
    return;
  }

  const getCardTitleByType = (type: ZohoReportType) => {
    if (type === undefined) return '';
    const reportName = reports?.data?.find((card) => card.type === type)?.name;
    const defaultReportName = ZohoCardTypes.find((card) => card.type === type)?.name;
    if (reportName) {
      return reportName;
    }
    else {
      return defaultReportName;
    }
  };


  return {
    usersInfo,
    reports,
    connectZoho,
    getCardIdByType,
    getCardDataByType,
    getOrgOwners,
    ownersLoading,
    orgOwners,
    getZohoReport,
    fetchAllReports,
    fetchReportByType,
    totalLeadWorked,
    callCount,
    callStatus,
    totalCalls,
    totalDemoBook,
    bookingCount,
    connectedBookingDate,
    connectionRate,
    connectZohoLoading,
    usersInfoLoading,
    reportsLoading,
    getUsersInfo,
    checkConnectedZoho,
    checkConnectedLoading,
    status,
    setStatus,
    resetStatus: () => setStatus(IntegrationStatus.None),
    zohoConnected,
    getCardTitleByType,
    isFetched,
    isAllChartsEmpty: !reports?.data || reports?.data?.length === 0,
    setOrgOwners,
    getZohoClient,
    zohoClients,
  };
}

export default useZoho;
