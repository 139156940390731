import { ApexOptions } from "apexcharts";
import { memo } from "react";
import Chart from "react-apexcharts";
import { useRecoilValue } from "recoil";
import { DATE_TIME_FORMAT, EMPTY_DATA_COLOR } from "../../../../../constants/app-constants";
import useMockupData from "../../../../../hooks/useMockupData";
import { roiChartDataSelector } from "../../../../../states/dashboard";
import { NUMBER_UTIL } from "../../../../../utils/number.utils";
import "./index.scss";

interface Props {
  chartId: string;
  height: number;
}

function LineInvestmentChart(props: Props) {
  const { chartId, height } = props;
  const roiData = useRecoilValue(roiChartDataSelector);
  const isEmpty = !roiData || !roiData.length;
  const mockupData = useMockupData();
  const data = isEmpty ? mockupData : roiData;
  const color = isEmpty ? EMPTY_DATA_COLOR : "#0185de";

  const series = [
    {
      name: "Total Lead Value",
      data: data.map((x) => x.value),
    },
  ];

  const options: ApexOptions = {
    chart: {
      id: chartId,
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: "datetime",
      categories: data.map((x) => x.label),
      labels: {
        show: false,
        format: DATE_TIME_FORMAT.chartDateFormat,
        datetimeUTC: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    tooltip: {
      enabled: true,
      x: {
        show: true,
        format: DATE_TIME_FORMAT.chartDateFormat,
      },
      y: {
        formatter: (val) => {
          return NUMBER_UTIL.convertNumericToFormattedAmount(val);
        },
      },
    },
    stroke: {
      width: 2,
      curve: "smooth",
      colors: [color],
    },
    fill: {
      colors: [color],
    },
    grid: {
      show: false,
      padding: {
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
      },
    },
  };

  return (
    <div
      style={{ height: height }}
      className={`${isEmpty ? "chart-disabled " : ""}line-investment-chart`}
    >
      <Chart
        options={options}
        series={series}
        type="area"
        width={"100%"}
        height={height}
      />
    </div>
  );
}

export default memo(LineInvestmentChart);
