import { Badge, Col, Row, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import LineFollowerChart from "../../../dashboard/charts/social-dashboard-chart/line-followers";
import "./index.scss";
import TopPosts from "../top-posts";
import BarChartSocial from "../../../dashboard/charts/social-dashboard-chart/bar-chart-social";
import Engagement from "./engagement";
import { socialDashboardDateRangeState } from "../../../../../states/social-dashboard";
import { useEffect, useMemo, useState } from "react";
import {
  FollowerReportModel,
  SocialDateReportModel,
} from "../../../../../models/social.model";
import {
  DefaultFollowersReport,
  DefaultSocialDateReport,
} from "../../../../../constants/app-constants";
import useClient from "../../../../../hooks/useClient";
import { SocialApi } from "../../../../../apis/social.api";
import SocialUtils from "../../../../../utils/social";
import { NUMBER_UTIL } from "../../../../../utils/number.utils";
import Volatility from "../../../../common/volatility";

function Overview() {
  const { t } = useTranslation();
  const { clientId } = useClient();

  const [loadingTotalPost, setLoadingTotalPost] = useState(false);
  const [loadingTotalImpression, setLoadingTotalImpression] = useState(false);
  const [loadingFollowers, setLoadingFollowers] = useState(false);
  const [totalPostReport, setTotalPostReport] = useState<SocialDateReportModel>(
    DefaultSocialDateReport
  );
  const [totalFollowersReport, setTotalFollowersReport] =
    useState<FollowerReportModel>(DefaultFollowersReport);
  const [totalImpressionReport, setTotalImpressionReport] =
    useState<SocialDateReportModel>(DefaultSocialDateReport);

  const [totalLikeReport, setTotalLikeReport] = useState<SocialDateReportModel>(
    DefaultSocialDateReport
  );
  const [totalShareReport, setTotalShareReport] =
    useState<SocialDateReportModel>(DefaultSocialDateReport);
  const [totalCommentReport, setTotalCommentReport] =
    useState<SocialDateReportModel>(DefaultSocialDateReport);
  const [loadingEngagement, setLoadingEngagement] = useState(false);
  const dateRange = useRecoilValue(socialDashboardDateRangeState);

  const { startDate, endDate } = dateRange;
  const params = { startDate, endDate, clientId };

  const getTotalPost = async () => {
    setLoadingTotalPost(true);
    try {
      const { data } = await SocialApi.SocialDashboard.getTotalPostDashboard(
        params
      );
      setTotalPostReport(data);
    } catch (error) {}
    setLoadingTotalPost(false);
  };

  const getTotalImpression = async () => {
    setLoadingTotalImpression(true);
    try {
      const { data } = await SocialApi.SocialDashboard.getTotalViewDashboard(
        params
      );
      setTotalImpressionReport(data);
    } catch (error) {}
    setLoadingTotalImpression(false);
  };

  const getFollowers = async () => {
    setLoadingFollowers(true);
    try {
      const { data } = await SocialApi.SocialDashboard.getFollowersDashboard(
        params
      );
      setTotalFollowersReport(data);
    } catch (error) {}
    setLoadingFollowers(false);
  };

  const getLikeReport = async () => {
    const { data } = await SocialApi.SocialDashboard.getTotalLikeDashboard(
      params
    );
    setTotalLikeReport(data);
  };

  const getCommentReport = async () => {
    const { data } = await SocialApi.SocialDashboard.getTotalCommentDashboard(
      params
    );
    setTotalCommentReport(data);
  };

  const getShareReport = async () => {
    const { data } = await SocialApi.SocialDashboard.getTotalShareDashboard(
      params
    );
    setTotalShareReport(data);
  };

  const initEngagement = async () => {
    setLoadingEngagement(true);
    try {
      await Promise.all([
        getLikeReport(),
        getCommentReport(),
        getShareReport(),
      ]);
    } catch (error) {}
    setLoadingEngagement(false);
  };

  const initData = async () => {
    getTotalPost();
    getTotalImpression();
    getFollowers();
  };

  useEffect(() => {
    if (startDate && endDate && clientId) {
      initData();
      initEngagement();
    }
  }, [dateRange]);

  const totalPostDataChart = useMemo(() => {
    return totalPostReport.rows.length
      ? SocialUtils.createChartDataset(dateRange, totalPostReport.rows)
      : [];
  }, [totalPostReport]);

  const totalImpressionDataChart = useMemo(() => {
    return totalImpressionReport.rows.length
      ? SocialUtils.createChartDataset(dateRange, totalImpressionReport.rows)
      : [];
  }, [totalImpressionReport]);

  const totalFollowerDataChart = useMemo(() => {
    const rows = totalFollowersReport.rows.map((r) => {
      return { ...r, value: r.totalFollowers };
    });
    return totalFollowersReport.rows.length
      ? SocialUtils.createChartDataset(dateRange, rows)
      : [];
  }, [totalFollowersReport]);

  const newFollowerDataChart = useMemo(() => {
    const rows = totalFollowersReport.rows.map((r) => {
      return { ...r, value: r.newFollowers };
    });
    return totalFollowersReport.rows.length
      ? SocialUtils.createChartDataset(dateRange, rows)
      : [];
  }, [totalFollowersReport]);
  return (
    <>
      <div className="home-page__session">
        <h4 className="home-page__sessionTitle">{t("dashboard.reach")}</h4>
        <div className="social-dashboard-overview">
          <Row gutter={16}>
            <Col xs={24} sm={12} xl={12} className="col-total-lead">
              <Spin spinning={loadingFollowers}>
                <div className="rl-card">
                  <p className="rl-card-title">
                    <span>{t("dashboard.followers")}</span>
                    <span>{dateRange.totalDays}d</span>
                  </p>

                  <div className="follower-chart">
                    <div className="follower-chart-detail">
                      <div className="follower-chart-detail__total">
                        <Badge text="Total Followers" color="#0185de" />
                        <span className="rl-card-count">
                          <span className="total">
                            {NUMBER_UTIL.convertNumericToFormattedString(
                              totalFollowersReport.total.totalFollowers
                            )}
                          </span>
                          <span className="volatility">
                            <Volatility value={
                                totalFollowersReport.volatility.totalFollowers
                              }
                            />
                          </span>
                        </span>
                      </div>
                      <div className="follower-chart-detail__new">
                        <Badge text="New Followers" color="#9bc13c" />
                        <span className="rl-card-count">
                          <span className="total">
                            {NUMBER_UTIL.convertNumericToFormattedString(
                              totalFollowersReport.total.newFollowers
                            )}
                          </span>
                          <span className="volatility">
                             <Volatility value={
                                totalFollowersReport.volatility.newFollowers
                              }
                            />
                          </span>
                        </span>
                      </div>
                    </div>
                    <div className="follower-chart-line">
                      <LineFollowerChart
                        height={132}
                        chartId="followers"
                        totalFollowerDataChart={totalFollowerDataChart}
                        newFollowerDataChart={newFollowerDataChart}
                      />
                    </div>
                  </div>
                </div>
              </Spin>
            </Col>

            <Col xs={24} sm={24} xl={6} className="col-roi">
              <div className="rl-card">
                <p className="rl-card-title">
                  <span>{t("dashboard.totalPosts")}</span>
                  <span>{dateRange.totalDays}d</span>
                </p>
                <span className="rl-card-count">
                  <span className="total">
                    {NUMBER_UTIL.convertNumericToFormattedString(
                      totalPostReport.total
                    )}
                  </span>
                  <span className="volatility">
                    <Volatility value={totalPostReport.volatility}/>
                  </span>
                </span>
                <Spin spinning={loadingTotalPost}>
                  <BarChartSocial
                    title={t("dashboard.totalPosts")}
                    height={100}
                    chartId="uniqueSearchChart"
                    data={totalPostDataChart}
                  />
                </Spin>
              </div>
            </Col>

            <Col xs={24} sm={12} xl={6} className="col-unique-search">
              <div className="rl-card">
                <p className="rl-card-title">
                  <span>{t("dashboard.totalImpressions")}</span>
                  <span>{dateRange.totalDays}d</span>
                </p>
                <span className="rl-card-count">
                  <span className="total">
                    {NUMBER_UTIL.convertNumericToFormattedString(
                      totalImpressionReport.total
                    )}
                  </span>
                  <span className="volatility">
                    <Volatility value={totalImpressionReport.volatility}/>
                  </span>
                </span>
                <Spin spinning={loadingTotalImpression}>
                  <BarChartSocial
                    title={t("dashboard.totalImpressions")}
                    height={100}
                    chartId="uniqueSearchChart"
                    data={totalImpressionDataChart}
                  />
                </Spin>
              </div>
            </Col>
          </Row>

          <Engagement
            totalCommentReport={totalCommentReport}
            totalLikeReport={totalLikeReport}
            totalShareReport={totalShareReport}
            totalViewReport={totalImpressionReport}
            loading={loadingEngagement}
          />
        </div>
      </div>
      <div className="home-page__session">
        <h4 className="home-page__sessionTitle">{t("dashboard.topPosts")}</h4>
        <TopPosts />
      </div>
    </>
  );
}

export default Overview;
