import React from "react";
import useClient from "../../../hooks/useClient";
import SVGIcons from "../../icons/svgs";

const Volatility: React.FC<{ value: number | undefined }> = ({ value }) => {
  value = value ?? 0;
  const { client } = useClient();

  if(!client?.showTrendIndicator) return null;

  if (Math.floor(value) > 0) {
    return (
      <span className="text-success">
        {Math.floor(value)}% <SVGIcons.TrendUpIcon />
      </span>
    );
  }

  if (Math.floor(value) === 0) {
    return (
      <span className="text-warning">
        0% <SVGIcons.MinusIcon />
      </span>
    );
  }

  if (Math.floor(value) < 0) {
    return (
      <span className="text-error">
        {Math.floor(0 - value)}% <SVGIcons.TrenDownIcon />
      </span>
    );
  }

  return null;
};

export default Volatility;
