import moment from "moment";
import { atom, selector } from "recoil";
import {
  DATE_TIME_FORMAT,
  REVIEW_NETWORK_DEFAULT_NAME,
} from "../constants/app-constants";
import { APP_STATE_KEYS } from "../constants/app-state.constants";
import { DateRangeValues } from "../constants/app.enums";
import {
  beReportDefaulValue,
  BeReportModel,
  TopNewworkModel,
} from "../models/be.model";
import { ChartDataModel } from "../models/chart.model";
import { CallReportModel, CallRow } from "../models/cr.model";
import {
  GaGeneralModel,
  GaRowModel,
  GaTotalLeadsModel,
  GaTrafficChanelModel,
} from "../models/ga.model";
import { GfReportModel } from "../models/gf.model";
import { GLModel, GLRowModel } from "../models/gl.model";
import DateUtils from "../utils/date.utils";

export interface DateRange {
  startDate: string;
  endDate: string;
  totalDays: number;
  value: string;
}

interface GaGeneralState {
  loading: boolean;
  data: GaGeneralModel | undefined;
}

interface GaTotalLeadsState {
  loading: boolean;
  data: GaTotalLeadsModel | undefined;
}

interface GaTrafficChanelState {
  loading: boolean;
  data: GaTrafficChanelModel | undefined;
}

interface CallReportState {
  loading: boolean;
  data: CallReportModel | undefined;
}

interface FormState {
  loading: boolean;
  data: GfReportModel | undefined;
}

interface BirdeyeState {
  loading: boolean;
  data: BeReportModel;
}

interface GLState {
  loading: boolean;
  data: GLModel | undefined;
}

const dashboardGaGeneralState = atom<GaGeneralState>({
  key: APP_STATE_KEYS.DASHBOARD_GA_GENERAL_STATE,
  default: {
    loading: false,
    data: undefined,
  },
});

const dasboardDateRangeState = atom({
  key: APP_STATE_KEYS.DASHBOARD_DATE_RANGE_STATE,
  default: {
    startDate: "",
    endDate: "",
    totalDays: 0,
    value: "",
  },
});

const dashboardGaTrafficState = atom<GaTrafficChanelState>({
  key: APP_STATE_KEYS.DASHBOARD_GA_TRAFFIC_STATE,
  default: {
    loading: false,
    data: undefined,
  },
});

const dashboardCallsState = atom<CallReportState>({
  key: APP_STATE_KEYS.DASHBOARD_CALLS_STATE,
  default: {
    loading: false,
    data: undefined,
  },
});

const dashboardGLState = atom<GLState>({
  key: APP_STATE_KEYS.DASHBOARD_GL_STATE,
  default: {
    loading: false,
    data: undefined,
  },
});

const glChartSelector = selector<GLRowModel[]>({
  key: "glChartSelector",
  get: ({ get }) => {
    const dateRange = get(dasboardDateRangeState);
    const { data } = get(dashboardGLState);

    if (!data || !data.rows || !data.rows.length) {
      return [];
    }

    const newArray: GLRowModel[] = [];

    for (
      var dt = moment(dateRange.startDate);
      dt <= moment(dateRange.endDate);
      dt.add(1, "d")
    ) {
      const element = dt.format(DATE_TIME_FORMAT.isoDate);

      const call = data.rows.find(
        (x) => moment(x.date).format(DATE_TIME_FORMAT.isoDate) === element
      );
      if (call) {
        newArray.push(call);
      } else {
        newArray.push({
          date: dt.format(),
          impressionsDesktopMaps: 0,
          impressionsDesktopSearch: 0,
          impressionsMobileMaps: 0,
          impressionsMobileSearch: 0,
          impressionsMaps: 0,
          impressionsSearch: 0,
          directionRequests: 0,
          callClicks: 0,
          websiteClicks: 0,
        });
      }
    }

    return newArray;
  },
});

const generalChartSelector = selector<GaRowModel[]>({
  key: "generalChartSelector",
  get: ({ get }) => {
    const dateRange = get(dasboardDateRangeState);
    const { data } = get(dashboardGaGeneralState);

    if (!data || !data.rows || !data.rows.length) {
      return [];
    }

    const newArray: GaRowModel[] = [];

    for (
      var dt = moment(dateRange.startDate);
      dt <= moment(dateRange.endDate);
      dt.add(1, "d")
    ) {
      const element = dt.format(DATE_TIME_FORMAT.isoDate);

      const call = data.rows.find(
        (x) => moment(x.date).format(DATE_TIME_FORMAT.isoDate) === element
      );
      if (call) {
        newArray.push(call);
      } else {
        newArray.push({
          date: dt.format(),
          avgSessionDuration: 0,
          bounceRate: 0,
          newUsers: 0,
          organicSearches: 0,
          pageviews: 0,
          returningUsers: 0,
          searchUniques: 0,
          users: 0,
        });
      }
    }

    return newArray;
  },
});

const callsFullDateSelector = selector<CallRow[]>({
  key: "callsFullDateSelector",
  get: ({ get }) => {
    const dateRange = get(dasboardDateRangeState);
    const { data } = get(dashboardCallsState);
    const callReport = data;

    if (!callReport || !callReport.rows || !callReport.total) {
      return [];
    }

    const newArray: CallRow[] = [];

    for (
      var dt = moment(dateRange.startDate);
      dt <= moment(dateRange.endDate);
      dt.add(1, "d")
    ) {
      const element = dt.format(DATE_TIME_FORMAT.isoDate);

      const call = callReport.rows.find(
        (x) => moment(x.date).format(DATE_TIME_FORMAT.isoDate) === element
      );
      if (call) {
        newArray.push(call);
      } else {
        newArray.push({
          date: dt.format(),
          answered: 0,
          missed: 0,
          total: 0,
        });
      }
    }

    return newArray;
  },
});

const dashboardBirdeyeState = atom<BirdeyeState>({
  key: APP_STATE_KEYS.DASHBOARD_BIRDEYE,
  default: {
    loading: false,
    data: beReportDefaulValue,
  },
});

const dashboardFormsState = atom<FormState>({
  key: APP_STATE_KEYS.DASHBOARD_FORMS_STATE,
  default: {
    loading: false,
    data: undefined,
  },
});

const dashboardTotalLeadsState = atom<GaTotalLeadsState>({
  key: APP_STATE_KEYS.DASHBOARD_TOTAL_LEADS_STATE,
  default: {
    data: undefined,
    loading: false,
  },
});

const topNetworksSelector = selector<TopNewworkModel[]>({
  key: "topNetworksSelector",
  get: ({ get }) => {
    const { data } = get(dashboardBirdeyeState);
    const topNetworks = data?.topNetworks;
    const newArray: TopNewworkModel[] = [];
    for (let index = 0; index < 3; index++) {
      if (topNetworks && topNetworks.length && topNetworks[index]) {
        newArray.push(topNetworks[index]);
      } else {
        newArray.push({
          name: REVIEW_NETWORK_DEFAULT_NAME,
          negative: 0,
          positive: 0,
          review: 0,
        });
      }
    }

    return newArray;
  },
});

const formsFullDateSelector = selector<ChartDataModel[]>({
  key: "formsFullDateSelector",
  get: ({ get }) => {
    const dateRange = get(dasboardDateRangeState);
    const { data } = get(dashboardFormsState);
    const rows = data?.rows;

    if (!rows || !rows.length) {
      return [];
    }

    const newArray: ChartDataModel[] = [];

    for (
      var dt = moment(dateRange.startDate);
      dt <= moment(dateRange.endDate);
      dt.add(1, "d")
    ) {
      const element = dt.format(DATE_TIME_FORMAT.isoDate);

      const form = rows.filter(
        (x) => moment(x.date).format(DATE_TIME_FORMAT.isoDate) === element
      );
      newArray.push({
        label: dt.format(),
        value:
          form && form.length
            ? form.map((x) => x.entries).reduce((a, b) => a + b, 0)
            : 0,
      });
    }

    return newArray;
  },
});

const reviewBarChartSelector = selector<ChartDataModel[]>({
  key: "reviewBarchartFullDateSelector",
  get: ({ get }) => {
    const { data } = get(dashboardBirdeyeState);
    const reviews = data?.reviews;
    const dateRange = get(dasboardDateRangeState);

    if (!reviews || !reviews.length) {
      return [];
    }

    const reviewChartData: ChartDataModel[] = reviews.map((x) => {
      return { label: x.date, value: x.review };
    });

    return convertChartData(dateRange, reviewChartData);
  },
});

const totalLeadsChartDataSelector = selector<ChartDataModel[]>({
  key: "totalLeadsBarchartSelector",
  get: ({ get }) => {
    const dateRange = get(dasboardDateRangeState);
    const { data } = get(dashboardTotalLeadsState);

    if (!data || !data.rows || !data.rows.length) {
      return [];
    }

    const reviewChartData: ChartDataModel[] = data.rows.map((x) => {
      return { label: x.date, value: x.value };
    });

    return convertChartData(dateRange, reviewChartData);
  },
});

const roiChartDataSelector = selector<ChartDataModel[]>({
  key: "roiChartDataSelector",
  get: ({ get }) => {
    const { data } = get(dashboardTotalLeadsState);
    const dateRange = get(dasboardDateRangeState);

    if (!data || !data.rows || !data.rows.length || !data.total) {
      return [];
    }

    const reviewChartData: ChartDataModel[] = data.rows.map((x) => {
      return {
        label: x.date,
        value: x.value * data.avgValueOfNewCustomer * data.conversionRate / 100,
      };
    });

    return getChartDates(dateRange, reviewChartData, true);
  },
});

const uniqueSearchSelector = selector<ChartDataModel[]>({
  key: "uniqueSearchSelector",
  get: ({ get }) => {
    const { data } = get(dashboardGaGeneralState);
    const dateRange = get(dasboardDateRangeState);

    if (!data || !data.rows) {
      return [];
    }

    const reviewChartData: ChartDataModel[] = data.rows.map((x) => {
      return { label: x.date, value: x.newUsers };
    });

    return convertChartData(dateRange, reviewChartData);
  },
});

function convertChartData(
  dateRange: DateRange,
  reviewChartData: ChartDataModel[]
) {
  switch (dateRange.value) {
    case DateRangeValues.lastYear:
      return getChartMonths(dateRange, reviewChartData);
    case DateRangeValues.last7days:
    case DateRangeValues.lastMonth:
      return getChartDates(dateRange, reviewChartData);
    case DateRangeValues.customRange:
      if (dateRange.totalDays <= 31) {
        return getChartDates(dateRange, reviewChartData);
      }
      if (dateRange.totalDays > 300) {
        return getChartMonths(dateRange, reviewChartData);
      } else {
        return getChartDateRange(dateRange, reviewChartData);
      }
    default:
      return getChartDateRange(dateRange, reviewChartData);
  }
}

function getChartDateRange(
  dateRange: DateRange,
  reviewChartData: ChartDataModel[]
) {
  const newArray: ChartDataModel[] = [];

  var groupCount = Math.floor(dateRange.totalDays / 16);

  let value = 0;
  let label = "";

  let index = 0;
  for (
    var dt = moment(dateRange.startDate);
    dt <= moment(dateRange.endDate);
    dt.add(1, "d")
  ) {
    const element = dt.format(DATE_TIME_FORMAT.isoDate);
    if (index % groupCount === 0) {
      label = DateUtils.dateAndMonthView(element);
    }

    var items = reviewChartData.filter(
      (x) => DateUtils.isoDate(x.label) === element
    );

    items.forEach((x) => {
      value = value + x.value;
    });

    if (
      index % groupCount === groupCount - 1 ||
      index === dateRange.totalDays - 1
    ) {
      const nextDate = DateUtils.dateAndMonthView(element);

      if (label !== nextDate) {
        label = `${label} - ${nextDate}`;
      }

      newArray.push({
        label: label,
        value: value,
      });

      label = "";
      value = 0;
    }
    index++;
  }

  return newArray;
}

function getChartMonths(
  dateRange: DateRange,
  reviewChartData: ChartDataModel[]
) {
  const newArray: ChartDataModel[] = [];

  for (let index = 0; index < 12; index++) {
    const element = moment(dateRange.startDate).add(index, "M");
    const label = DateUtils.monthView(element);
    let value = 0;
    var items = reviewChartData.filter(
      (x) => DateUtils.monthView(x.label) === label
    );

    items.forEach((x) => {
      value = value + x.value;
    });

    newArray.push({
      label: label,
      value: value,
    });
  }

  return newArray;
}

function getChartDates(
  dateRange: DateRange,
  reviewChartData: ChartDataModel[],
  isDateTime = false
) {
  const newArray: ChartDataModel[] = [];

  for (
    let dt = new Date(dateRange.startDate);
    dt <= new Date(dateRange.endDate);
    dt.setDate(dt.getDate() + 1)
  ) {
    const element = moment(dt).format(DATE_TIME_FORMAT.isoDate);

    let value = 0;
    var items = reviewChartData.filter(
      (x) => DateUtils.isoDate(x.label) === DateUtils.isoDate(element)
    );

    items.forEach((x) => {
      value = value + x.value;
    });

    newArray.push({
      label: isDateTime ? element : DateUtils.dateAndMonthView(element),
      value: value,
    });
  }

  return newArray;
}

export {
  dashboardGaGeneralState,
  dashboardGaTrafficState,
  dashboardCallsState,
  dashboardBirdeyeState,
  dashboardFormsState,
  callsFullDateSelector,
  formsFullDateSelector,
  reviewBarChartSelector,
  totalLeadsChartDataSelector,
  dashboardTotalLeadsState,
  roiChartDataSelector,
  uniqueSearchSelector,
  topNetworksSelector,
  dasboardDateRangeState,
  generalChartSelector,
  dashboardGLState,
  glChartSelector,
};
