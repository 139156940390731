import { Col, Row, Tabs, TabsProps, Tooltip } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import SpinDiv from "../../../components/common/spin-div";
import AppDateRangePicker, {
  DateRangePickerValues,
} from "../../../components/controls/app-date-range-picker";
import AppMobileDateRangePicker from "../../../components/controls/app-mobile-date-range-picker";
import AddConnect from "../../../components/views/social/add-connect";
import Audience from "../../../components/views/social/dashboard/audience";
import DashboardContent from "../../../components/views/social/dashboard/content";
import Hashtags from "../../../components/views/social/dashboard/hashtags";
import Overview from "../../../components/views/social/dashboard/overview";
import { DateRangeValues } from "../../../constants/app.enums";
import useSocial from "../../../hooks/useSocial";
import { isMobileSelector } from "../../../states/common";
import { socialDashboardDateRangeState } from "../../../states/social-dashboard";
import "./index.scss";
import Categories from "../../../components/views/social/dashboard/categories";
import AppDateRangePicker2 from "../../../components/controls/app-date-range-picker-2";
import SVGIcons from "../../../components/icons/svgs";
import { DATE_TIME_FORMAT } from "../../../constants/app-constants";

export enum SocialDashboardItems {
  overview = "overview",
  content = "content",
  audience = "audience",
  hashtags = "hashtags",
  categories = "categories",
}
const SocialDashboard = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const setDateRange = useSetRecoilState(socialDashboardDateRangeState);
  const {
    isProfileConnected,
    loadingConnect,
    connectProfile,
    loading: loadingConnectSocial,
  } = useSocial();
  const isMobile = useRecoilValue(isMobileSelector);
  const [current, setCurrent] = useState<SocialDashboardItems>(
    SocialDashboardItems.overview
  );

  const items: TabsProps["items"] = [
    {
      label: t("social.dashboard.overview"),
      key: SocialDashboardItems.overview,
    },
    {
      label: t("social.dashboard.content"),
      key: SocialDashboardItems.content,
    },
    {
      label: t("social.dashboard.audience"),
      key: SocialDashboardItems.audience,
    },
    {
      label: t("social.dashboard.hashtags"),
      key: SocialDashboardItems.hashtags,
    },
    {
      label: t("social.dashboard.categories"),
      key: SocialDashboardItems.categories,
    },
  ];

  const onChange = (activeKey: string) => {
    setSearchParams({ ...searchParams, tab: activeKey });
  };

  const renderContent = useCallback(() => {
    switch (current) {
      case SocialDashboardItems.overview:
        return <Overview />;
      case SocialDashboardItems.content:
        return <DashboardContent />;
      case SocialDashboardItems.audience:
        return <Audience />;
      case SocialDashboardItems.hashtags:
        return <Hashtags />;
      case SocialDashboardItems.categories:
        return <Categories />;
      default:
        return <></>;
    }
  }, [current]);

  function dateChange(values: DateRangePickerValues) {
    const totalDays = values.endDate.diff(values.startDate, "days") + 1;
    const startDate = values.startDate.format(DATE_TIME_FORMAT.isoDateStartOfDay);
    const endDate = values.endDate.format(DATE_TIME_FORMAT.isoDateEndOfDay);

    setDateRange({
      totalDays: totalDays,
      endDate: endDate,
      startDate: startDate,
      value: values.value,
    });
  }

  useEffect(() => {
    if (tab) {
      const item = Object.values(SocialDashboardItems).includes(
        tab as SocialDashboardItems
      );
      if (item) setCurrent(tab as SocialDashboardItems);
    } else {
      setSearchParams({ ...searchParams, tab: SocialDashboardItems.overview });
    }
  }, [searchParams]);
  return (
    <div className="social-dashboard">
      <div className="page-header social-dashboard-header">
        <Row justify="space-between">
          <Col>
          
          <div className="page-header-with-icon" >
          <h4 className="page-header__title">
              {t("social.dashboard.title")}
            </h4>
              {/* <Tooltip
                overlayClassName="custom-tooltip"
                title={<span>{t("salesAnalytics.refreshLatestData")}</span>}
              >
                  <div className="icon-refresh social-dashboard" >
                <SVGIcons.RefreshIcon />
              </div>
              </Tooltip> */}
            </div>
          
          </Col>
          <Col>
            {isMobile ? (
              <AppMobileDateRangePicker
                defaulValue={DateRangeValues.lastMonth}
                onChange={dateChange}
                disabled={current === SocialDashboardItems.audience}
              />
            ) : (
              <AppDateRangePicker2
                defaulValue={DateRangeValues.lastMonth}
                onChange={dateChange}
                hasBorder={true}
                disabled={current === SocialDashboardItems.audience}
              />
            )}
          </Col>
        </Row>
      </div>

      <Tabs activeKey={current} items={items} onChange={onChange} />

      <SpinDiv
        loading={loadingConnectSocial}
        style={{ height: "calc(100vh - 300px)" }}
      >
        <div className="social-dashboard-body">
          {!isProfileConnected ? (
            <div className="social-dashboard-overview">
              <div className="background"></div>
              <div className="add-connect">
                <AddConnect
                  loadingConnect={loadingConnect}
                  connectProfile={connectProfile}
                />
              </div>
            </div>
          ) : (
            renderContent()
          )}
        </div>
      </SpinDiv>
    </div>
  );
};

export default SocialDashboard;
