import { DateSpanApi } from "@fullcalendar/core";
import { EventImpl } from "@fullcalendar/core/internal";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { Button, Modal, Space, Spin, Tooltip } from "antd";
import moment from "moment";
import momentTimezone from "moment-timezone";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { SocialApi } from "../../../apis/social.api";
import FilterList from "../../../components/common/filter";
import showNotification from "../../../components/common/notification";
import SpinDiv from "../../../components/common/spin-div";
import SVGIcons from "../../../components/icons/svgs";
import AddConnect from "../../../components/views/social/add-connect";
import ContentDrawer from "../../../components/views/social/calendar/content-drawer";
import ShareLinkModal from "../../../components/views/social/share-link-modal";
import AvatarSocial from "../../../components/views/social/social-content/components/avatar-social";
import DeleteContentModal from "../../../components/views/social/social-content/components/delete-content-modal";
import CreateContent from "../../../components/views/social/social-content/create-content";
import EditContentModal from "../../../components/views/social/social-content/edit-content/edit-content-modal";
import { CALENDAR_FILTER_DATA } from "../../../constants/app-constants";
import { PermissionSocial, PlanUsageType, PostStatusType, SocialType } from "../../../constants/app.enums";
import { ROUTE_PATHS } from "../../../constants/router.constants";
import useClient from "../../../hooks/useClient";
import useClientPlan from "../../../hooks/useClientPlan";
import useContent from "../../../hooks/useContent";
import useService from "../../../hooks/useService";
import useSocial from "../../../hooks/useSocial";
import { ContentModel } from "../../../models/content.model";
import {
  listContentCalendarState,
  listPostTypeState,
  listProfileSelector,
  listProfileState,
  listTagState,
} from "../../../states/social";
import DateUtils from "../../../utils/date.utils";
import SocialUtils from "../../../utils/social";
import "./index.scss";
import { renderPlatforms } from "../content";

interface EventModel {
  title: string;
  date: string;
  dateRender: string;
  id: string;
  platform: SocialType;
  contentId: number;
  errorMessage: string;
  platformCount: number;
  startEditable: boolean;
  isFirst: boolean;
}

const SocialCalendar = () => {
  const { t } = useTranslation();
  const { client, clientId, hasPermissionSocial, timezoneId } = useClient();
  const { SocialService } = useService();
  const { isProfileConnected, loading, loadingConnect, connectProfile } =
    useSocial();
  const { checkPremiumPlan } = useClientPlan()

  const useContentProps = useContent();
  const {
    onCreateContentCalendar,
    onEditContentCalendar,
    contentSelected,
    openCreateModal,
    setOpenCreateModal,
    openDeleteModal,
    openEditModal,
    contentShareLink,
    setOpenEditModal,
    setContentShareLink,
    setOpenDeleteModal,
    setContentSelected,
    onOpenCreateWithAIModal,
    closeCreateModal,
  } = useContentProps;
  const [loadingCalendar, setLoadingCalendar] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [dateSelected, setDateSelected] = useState<moment.Moment | undefined>();
  const [isDragging, setIsDragging] = useState(false);
  const contentState = useRecoilValue(
    listContentCalendarState
  );
  const resetContentState = useResetRecoilState(listContentCalendarState);
  const listProfile = useRecoilValue(listProfileSelector);
  const listTag = useRecoilValue(listTagState);
  const listPostType = useRecoilValue(listPostTypeState);
  const url = `${window.location.origin}${ROUTE_PATHS.Social.ReviewContent}?contentId=${contentShareLink?.id}&clientId=${clientId}`;
  const [listSocialProfile, setListSocialProfile] = useRecoilState(listProfileState);

  const { records: listContent } = contentState;

  const dateRenderedRef = useRef<string[]>([]);
  const currentView = useRef<"dayGridMonth" | "timeGridWeek" | "timeGridDay">(
    "dayGridMonth"
  );
  const calendarRef = useRef<any>(null);
  const isCellRendering = useRef<NodeJS.Timeout>();

  const onChangeMonth = async (e: any) => {
    currentView.current = e.view.type;
    setLoadingCalendar(true);
    try {
      await SocialService.getListContentCalendar({
        startDate: DateUtils.convertLocalUTCToUTC(e.startStr, timezoneId).format(
          "YYYY-MM-DDTHH:mm:ss"
        ),
        endDate: DateUtils.convertLocalUTCToUTC(e.endStr, timezoneId).format(
          "YYYY-MM-DDTHH:mm:ss"
        ),
        pageIndex: 1,
        pageSize: 9999,
        clientId,
      });
    } catch (error) {
      console.log(error);
      setLoadingCalendar(false);
    }

    rerenderAddButtonWeekView(true);
    setLoadingCalendar(false);
  };

  const onCloseDrawer = () => {
    setOpenDrawer(false);
    setDateSelected(undefined);
  };

  const initListTag = async () => {
    clientId && (await SocialService.getListTag(clientId));
  };

  const initListPostType = async () => {
    clientId && (await SocialService.getListPostType(clientId));
  };

  const listEvent: EventModel[] = useMemo(() => {
    return listContent.reduce(
      (result: any, content: ContentModel, index, originArray) => {
        const {
          platformContents,
          id,
          date,
          errorMessage: contentErrorMessage,
        } = content;
        if (!date) return result;
        const socialContents = platformContents
          .filter((content) => content)
          .map((socialContent) => {
            if (!socialContent) return null;
            return {
              title: socialContent?.post || "",
              date: DateUtils.getDateWithTimezone(
                date,
                client?.timeZoneInfo?.id
              ).format("YYYY-MM-DDTHH:00"),
              dateRender: DateUtils.getDateWithTimezone(
                date,
                client?.timeZoneInfo?.id
              ).format("hh:mma"),
              id: socialContent?.id?.toString(),
              platform: socialContent.platform,
              contentId: id,
              errorMessage: socialContent?.errorMessage || contentErrorMessage,
              status: content.status,
            };
          })
          .filter((content) => content?.date);
        return [...result, ...socialContents];
      },
      []
    );
  }, [listContent]);

  const socialIcon = (platform: SocialType) => {
    switch (platform) {
      case SocialType.Facebook:
        return <SVGIcons.FacebookImage />;
      case SocialType.Instagram:
        return <SVGIcons.InstagramImage />;
      case SocialType.Tiktok:
        return <SVGIcons.TiktokImage />;
      case SocialType.Youtube:
        return <SVGIcons.YoutubeImage />;
      case SocialType.Google:
        return <SVGIcons.GoogleImage />;
      default:
        return <></>;
    }
  };

  function onAddContent(dayNumber: moment.Moment) {
    setOpenCreateModal(true);
    setDateSelected(dayNumber);
  }
  function onShowDrawer(dayNumber: moment.Moment) {
    setOpenDrawer(true);
    setDateSelected(dayNumber);
  }
  function onShowEditContent(contentId: number) {
    const content = listContent.find((content) => content.id === contentId);
    if (content) {
      setContentSelected({ ...content });
      setOpenEditModal(true);
    }
  }

  const onCreateContent = async () => {
    onCreateContentCalendar()
      .then((res) => {
        if (res) {
          setDateSelected(undefined);
          rerenderAddButtonWeekView(true);
        }
      })
      .catch((error) => console.log(error));
  };

  const onEditContent = async (contentId: number) => {
    await onEditContentCalendar(contentId);

    rerenderAddButtonWeekView(true);
  };

  const filterChange = async (values: any) => {
    setLoadingCalendar(true);
    await SocialService.getListContentCalendar({
      clientId,
      platforms: undefined,
      approvalStatus: undefined,
      statuses: undefined,
      tagIds: undefined,
      postTypes: undefined,
      pageIndex: 1,
      ...values,
    });

    rerenderAddButtonWeekView(true);
    setLoadingCalendar(false);
  };

  const reScheduleEvent = async (contentId: number, dateStr: string) => {
    setLoadingCalendar(true);
    const scheduledDate = momentTimezone.tz(dateStr, timezoneId).utc().format();

    try {
      await SocialApi.scheduleContent(
        contentId,
        scheduledDate
      );
      showNotification("success", t("social.content.saveContentSuccess"));
    } catch (error) {
      // showNotification("error", t("social.content.scheduleNowError"));
    }
    setLoadingCalendar(false);
  };

  const onDropEvent = async (event: EventImpl) => {
    const { contentId, platformCount } = event.extendedProps;
    const dateStr = event.startStr.slice(0, -1); // remove Z

    if (platformCount <= 1) {
      await reScheduleEvent(contentId, dateStr);
      await SocialService.getListContentCalendar({});
      rerenderAddButtonWeekView(true);
    } else {
      Modal.confirm({
        title: t("social.content.dropEventTitle"),
        content: <span>{t("social.content.dropEventDescription")}</span>,
        okText: t("social.content.proceed"),
        cancelText: t("common.cancel"),
        cancelButtonProps: { type: "text" },
        icon: "",
        className: "confirm-modal",
        centered: true,
        onOk: async () => {
          await reScheduleEvent(contentId, dateStr);
          await SocialService.getListContentCalendar({});
          rerenderAddButtonWeekView(true);
        },
        onCancel: async () => {
          await SocialService.getListContentCalendar({});
          rerenderAddButtonWeekView(true);
        },
      });
    }
  };

  const onAllowDropEvent = (
    dropInfo: DateSpanApi,
    draggedEvent: EventImpl | null
  ) => {
    const isPast =
      DateUtils.convertTimeToTimezone(timezoneId).format(
        "YYYY-MM-DDTHH:mm:ss"
      ) >
      momentTimezone(dropInfo.startStr.slice(0, -1)).format(
        "YYYY-MM-DDTHH:mm:ss"
      );
    if (isPast) {
      return false;
    }
    if (draggedEvent?.extendedProps?.status === PostStatusType.Posted) {
      return false;
    }
    return true;
  };

  const updateTodayHeaderRef = useRef<any>();

  const customTodayTitle = () => {
    if (updateTodayHeaderRef.current) {
      clearTimeout(updateTodayHeaderRef.current);
      updateTodayHeaderRef.current = undefined;
    }
    updateTodayHeaderRef.current = setTimeout(() => {
      var todays = document.getElementsByClassName("fc-day-today");

      if (!todays || !todays.length) {
        return;
      }

      const atags = todays[0].getElementsByClassName(
        "fc-col-header-cell-cushion"
      );
      if (!atags || !atags.length) return;

      const atag = atags[0];
      if (
        !atag.textContent ||
        !atag.innerHTML ||
        atag.innerHTML.includes("<span")
      )
        return;

      const arrayText = atag.textContent.split(" ");
      if (arrayText.length < 1) return;
      atag.innerHTML = `<div> ${arrayText[0]} <span> ${arrayText[1]} </span> </div>`;
    }, 300);
  };

  useEffect(() => {
    initListTag();
    initListPostType();
    return () => {
      resetContentState();
    };
  }, [clientId]);

  useEffect(() => {
    rerenderAddButtonWeekView(true);
  }, [listContent, clientId]);

  const renderAddButtonWeekview = (isRerender = false) => {
    const gridCols = document.getElementsByClassName("fc-timegrid-cols")[0];
    if (!gridCols) return;
    const gridCol = Array.from(
      gridCols.getElementsByClassName("fc-timegrid-col")
    );
    gridCol.forEach((col: any) => {
      const dateCol = col.getAttribute("data-date");
      if (!isRerender && dateRenderedRef.current.includes(dateCol)) return;
      if (col.getElementsByClassName("fc-timegrid-col-bg").length) {
        const colBg = col.getElementsByClassName("fc-timegrid-col-bg")[0];
        if (isRerender) {
          colBg.innerHTML = "";
        }
        // height of one cell is 80px
        const cellHeight = 80;
        // for from 0h to 24h
        for (let index = 0; index < 24; index++) {
          let timeSlotOverlay = document.createElement("div");
          timeSlotOverlay.className = "time-slot-overlay";
          timeSlotOverlay.style.position = "absolute";
          timeSlotOverlay.style.inset = `${index * cellHeight}px 0 -${index * cellHeight + cellHeight
            }px`;
          colBg.appendChild(timeSlotOverlay);

          let eventDiv = col.getElementsByClassName(
            "fc-timegrid-event-harness"
          );

          const getEventShownCount = (index: number, cellHeight: number) => {
            let eventCount = 0;
            const top = cellHeight * index;
            for (let element of eventDiv) {
              const offsetTop = element.offsetTop;
              if (offsetTop > top && offsetTop < top + cellHeight) {
                eventCount++;
              }
            }

            // set margin top for second event
            if (eventCount > 0) {
              for (let indexElement in eventDiv) {
                const offsetTop = eventDiv[indexElement].offsetTop;
                if (offsetTop > top && offsetTop < top + cellHeight) {
                  if (eventDiv[indexElement].style.zIndex === "1")
                    eventDiv[indexElement].style.marginTop = "5px";
                  // second event
                  if (eventDiv[indexElement].style.zIndex === "2")
                    eventDiv[indexElement].style.marginTop = "28px";
                }
              }
            }

            return eventCount;
          };
          const hourString = index < 10 ? "0" + index : index + "";
          const dateTimeStr = `${dateCol}T${hourString}:00:00`;
          const dateTime = momentTimezone.tz(dateTimeStr, timezoneId);
          const btnNewContent = document.createElement("div");
          btnNewContent.className = "btn-new-content";
          btnNewContent.innerText = "+ New Content";
          btnNewContent.onclick = () => {
            onAddContent(dateTime);
          };

          const btnShowMore = document.createElement("div");
          btnShowMore.className = "btn-show-more";
          btnShowMore.innerText = "Show All";
          btnShowMore.onclick = () =>
            onShowDrawer(moment(`${dateCol}T${hourString}:00:00`));

          const eventCount = getEventShownCount(index, cellHeight);
          if (eventCount < 2) {
            btnNewContent.className =
              btnNewContent.className + " btn-full-width";
            btnNewContent.style.position = "absolute";
            // height of one event is 20px, 5 is margin
            const eventHeight = 20;
            btnNewContent.style.top = `${eventCount * (eventHeight + 5)}px`;
            btnNewContent.style.right = "0";
            btnNewContent.style.left = "0";
            btnNewContent.innerText = "+";
          } else {
            timeSlotOverlay.appendChild(btnShowMore);
          }
          if (
            dateCol >=
            DateUtils.getDateWithTimezone(
              undefined,
              client?.timeZoneInfo?.id
            ).format("YYYY-MM-DD")
          )
            timeSlotOverlay.appendChild(btnNewContent);
        }
      }
      dateRenderedRef.current.push(dateCol);
    });
  };

  const renderCellFooter = (
    eventCount: HTMLCollectionOf<Element>,
    dayframeEl: Element,
    date: moment.Moment
  ) => {
    const footerEl = document.createElement("div");
    const isPast =
      DateUtils.convertTimeToTimezone(timezoneId).format("YYYY-MM-DD") >
      momentTimezone(date).format("YYYY-MM-DD");
    const dateLocalStr = momentTimezone.tz(
      date.utc().format().slice(0, -1),
      timezoneId
    );
    if (eventCount.length < 4) {
      if (!isPast) {
        footerEl.className = "day-grid-footer";
        const btnNewContent = document.createElement("div");
        btnNewContent.className = "btn-plus";
        btnNewContent.innerText = "+";
        btnNewContent.onclick = () => {
          onAddContent(dateLocalStr);
        };
        footerEl.appendChild(btnNewContent);
        dayframeEl.appendChild(btnNewContent);
        const showAllEl =
          dayframeEl.getElementsByClassName("day-grid-footer")[0];
        showAllEl && showAllEl.remove();
      }
    } else {
      footerEl.className = "day-grid-footer";
      const btnShowMore = document.createElement("div");
      btnShowMore.className = "btn-show-more";
      btnShowMore.innerText = "Show All";
      btnShowMore.onclick = () => onShowDrawer(dateLocalStr);
      footerEl.appendChild(btnShowMore);

      if (!isPast) {
        const btnNewContent = document.createElement("div");
        btnNewContent.className = "btn-new-content";
        btnNewContent.innerText = "+ New Content";
        btnNewContent.onclick = () => {
          onAddContent(dateLocalStr);
        };
        footerEl.appendChild(btnNewContent);
      }
      dayframeEl.appendChild(footerEl);
    }
  };

  const rerenderAddButtonWeekView = (isRerender = false) => {
    if (
      currentView.current === "timeGridWeek" ||
      currentView.current === "timeGridDay"
    ) {
      setTimeout(() => {
        renderAddButtonWeekview(isRerender);
      }, 200);
    }
  };

  const dataFilter = {
    platforms: renderPlatforms(listSocialProfile.profiles, CALENDAR_FILTER_DATA["platforms"]),
    statuses: CALENDAR_FILTER_DATA["statuses"],
    postTypes: listPostType.map((postType) => {
      return { name: SocialUtils.createKeyPostType(postType), value: postType };
    }),
    approvalStatus: CALENDAR_FILTER_DATA["approvalStatus"],
    tagIds: listTag.map((tag) => {
      return {
        name: tag.name,
        value: tag.id,
        color: tag.color,
      };
    }),
  };

  return (
    <div className="social-calendar">
      <div className="page-header">
        <h4 className="page-header__title">{t("social.calendar.title")}</h4>
      </div>
      <SpinDiv loading={loading}>
        <div className="social-calendar-body">
          {!isProfileConnected ? (
            <div className="no-connect">
              <div className="background"></div>
              <div className="add-connect">
                <AddConnect
                  loadingConnect={loadingConnect}
                  connectProfile={connectProfile}
                />
              </div>
            </div>
          ) : (
            <>
              <div className="calendar-header">
                <div className="calendar-header-left">
                  <Space>
                    {listProfile.map((profile) => {
                      return (
                        <AvatarSocial
                          avatar={profile.userImage}
                          socialType={profile.platform}
                          key={profile.id}
                        />
                      );
                    })}
                  </Space>
                </div>
                <div className="calendar-header-right">
                  <Space>
                    <FilterList data={dataFilter} onChange={filterChange} />
                    <Button
                      type="primary"
                      className="ant-btn-primary ant-btn-purple"
                      onClick={onOpenCreateWithAIModal}
                    >
                      <SVGIcons.SparklesIcon />
                      {t("social.aiFeature.writeWithAI")}
                    </Button>
                    {hasPermissionSocial(PermissionSocial.CanManage) && (
                      <Button
                        type="primary"
                        onClick={() => {
                          checkPremiumPlan(() => {
                            setOpenCreateModal(true)
                          }, PlanUsageType.PostPerCom)
                        }}
                      >
                        <SVGIcons.PlusIcon />
                        {t("social.content.createContent")}
                      </Button>
                    )}
                  </Space>
                </div>
              </div>
              <Spin spinning={loadingCalendar}>
                <FullCalendar
                  ref={calendarRef}
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  initialView="dayGridMonth"
                  headerToolbar={{
                    left: "prev,next today",
                    center: "title",
                    right: "dayGridMonth,timeGridWeek,timeGridDay",
                  }}
                  dayCellClassNames={isDragging ? "dragging" : ""}
                  viewClassNames={isDragging ? "dragging" : ""}
                  editable={true}
                  now={DateUtils.convertTimeToTimezone(timezoneId).format(
                    "YYYY-MM-DD"
                  )}
                  dayHeaderFormat={(arg: any) => {
                    // custom redius today header
                    customTodayTitle();

                    if (
                      document.getElementsByClassName("fc-dayGridMonth-view")
                        .length === 0
                    ) {
                      return `${moment(arg.date.marker).format("dddd")} ${arg.date.day
                        }`;
                    }
                    return moment(arg.date.marker)
                      .format("ddd")
                      .substring(0, 3);
                  }}
                  weekends={true}
                  datesSet={onChangeMonth}
                  events={listEvent}
                  eventClick={(e) =>
                    onShowEditContent(e.event.extendedProps.contentId)
                  }
                  eventContent={(e) => {
                    const { platform, errorMessage } = e.event.extendedProps;

                    return (
                      <Tooltip title={errorMessage}>
                        <div
                          className={`social-event-calendar ${errorMessage ? "error" : `platform-${platform}`
                            }`}
                        >
                          <div className="social-icon">
                            {socialIcon(e.event.extendedProps.platform)}
                          </div>
                          <div className="post">{e.event.title}</div>
                          <div className="time">
                            {e.event.extendedProps.dateRender}
                          </div>
                          <div className="icon-drag">
                            <SVGIcons.DragIcon />
                          </div>
                        </div>
                      </Tooltip>
                    );
                  }}
                  eventMaxStack={2}
                  timeZone={"UTC"}
                  navLinks={true}
                  eventDidMount={(eventInfo) => {
                    const { event, view, el: eventElement } = eventInfo;
                    if (view.type === "dayGridMonth") {
                      const date = momentTimezone(event.startStr);
                      const dateUTC = date.tz(timezoneId);
                      const cellEl = document.querySelectorAll(
                        `[data-date="${dateUTC.format("YYYY-MM-DD")}"]`
                      )[0];
                      const dayframeEl = cellEl?.getElementsByClassName(
                        "fc-daygrid-day-frame"
                      )[0];
                      setTimeout(() => {
                        const eventCount = dayframeEl.getElementsByClassName(
                          "fc-daygrid-event-harness"
                        );
                        if (
                          dayframeEl.getElementsByClassName("day-grid-footer")
                            .length === 0
                        ) {
                          eventCount.length >= 4 &&
                            renderCellFooter(eventCount, dayframeEl, date);
                        } else {
                          if (eventCount.length < 4) {
                            if (eventElement.offsetTop === 18) {
                              renderCellFooter(eventCount, dayframeEl, date);
                            }
                          }
                        }
                      }, 1000);
                    }
                  }}
                  dayCellDidMount={(cell) => {
                    const { el, view } = cell;
                    if (el) {
                      const isPast =
                        DateUtils.convertTimeToTimezone(timezoneId).format(
                          "YYYY-MM-DD"
                        ) > momentTimezone(cell.date).format("YYYY-MM-DD");
                      if (isPast) el.style.backgroundColor = "#9b9b9b1A";
                      else el.style.backgroundColor = "#fff";
                    }

                    if (view.type === "dayGridMonth") {
                      const dayframeEl = el.getElementsByClassName(
                        "fc-daygrid-day-frame"
                      )[0];
                      if (
                        dayframeEl.getElementsByClassName("day-grid-footer")
                          .length === 0
                      ) {
                        const eventCount = dayframeEl.getElementsByClassName(
                          "fc-daygrid-event-harness"
                        );
                        setTimeout(() => {
                          renderCellFooter(
                            eventCount,
                            dayframeEl,
                            momentTimezone(cell.date)
                          );
                        }, 500);
                      }
                    } else if (
                      view.type === "timeGridWeek" ||
                      view.type === "timeGridDay"
                    ) {
                      if (isCellRendering.current) {
                        clearTimeout(isCellRendering.current);
                        isCellRendering.current = undefined;
                      }
                      dateRenderedRef.current = [];
                      isCellRendering.current = setTimeout(() => {
                        rerenderAddButtonWeekView();
                      }, 100);
                    }
                    currentView.current = view.type as
                      | "dayGridMonth"
                      | "timeGridWeek";
                  }}
                  eventDrop={(info) => onDropEvent(info.event)}
                  eventAllow={onAllowDropEvent}
                  eventDragStart={(e) => setIsDragging(true)}
                  eventDragStop={(e) => setIsDragging(false)}
                />
              </Spin>
            </>
          )}
        </div>
      </SpinDiv>
      <CreateContent
        open={openCreateModal}
        close={() => {
          closeCreateModal();
          setDateSelected(undefined);
        }}
        scheduleTime={dateSelected}
        onCreateContent={onCreateContent}
      />
      <ContentDrawer
        open={openDrawer}
        close={onCloseDrawer}
        timeSelected={dateSelected}
        view={currentView.current}
        useContentProps={useContentProps}
      />

      {contentSelected && (
        <EditContentModal
          open={openEditModal}
          close={() => {
            setOpenEditModal(false);
            setTimeout(() => {
              setContentSelected(undefined);
            }, 500) // set timeout to keep close modal effect
          }}
          contentInfo={contentSelected}
          onEditContent={onEditContent}
        />
      )}

      <ShareLinkModal
        content={contentShareLink}
        close={() => {
          setContentShareLink(undefined);
        }}
        url={url}
      />

      {contentSelected && (
        <DeleteContentModal
          open={openDeleteModal}
          close={() => setOpenDeleteModal(false)}
          contentInfo={contentSelected}
          isCalendarPage={true}
        />
      )}
    </div>
  );
};

export default SocialCalendar;
