import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { ZohoApi } from "../../apis/zoho.api";
import showNotification from "../../components/common/notification";
import { ZohoRedirectUrl } from "../../constants/app-constants";
import { ROUTE_PATHS } from "../../constants/router.constants";
import useAdminSite from "../../hooks/useAdminSite";
import { clientAccessState } from "../../states/zoho";
import { CommonUtils } from "../../utils/common";
import "./index.scss";

function ZohoReturnPage() {
    const [searchParams] = useSearchParams();

    const [loading, setLoading] = useState<boolean>(false);
    const isAdmin = useAdminSite();

    const navigate = useNavigate();

    const setClientAccess = useSetRecoilState(
        clientAccessState
    );

    const getClientAccess = async () => {
        setLoading(true);
        try {
            const state = searchParams.get("state");
            const code = searchParams.get("code");
            if (code && state && (state.split("_").length === 2 || state.split("_").length === 3)) {
                const cId = Number(state.split("_")[0]);
                const customRedirect = state.split("_")[1];
                const isConnectNow = customRedirect !== ZohoRedirectUrl.AdminEditSiteShowModal;
                const isReAuthenticate = state.split("_")[2] === ZohoRedirectUrl.ReAuthenticate;
                try {
                    const res = await ZohoApi.getClientAccess(code, state ? cId : 0, isAdmin, isReAuthenticate, isConnectNow);
                    setClientAccess(res.data);
                } catch (error: any) {
                    setTimeout(() => {
                        if (error?.response?.data && typeof (error?.response?.data) === 'string') {
                            showNotification("delete", error?.response?.data);
                        }
                    }, 1000)
                }

                let url = "";
                switch (customRedirect) {
                    case ZohoRedirectUrl.SaleAnalytic:
                        url = ROUTE_PATHS.SalesAnalytics;
                        break;
                    case ZohoRedirectUrl.AdminViewSaleAnalytic:
                        url = CommonUtils.getAdminViewClientSiteRouter(ROUTE_PATHS.SalesAnalytics, cId)
                        break;
                    case ZohoRedirectUrl.GeneralSettings:
                        url = ROUTE_PATHS.GeneralSettings + "?tab=advanced";
                        break;
                    case ZohoRedirectUrl.AdminViewGeneralSettings:
                        url = CommonUtils.getAdminViewClientSiteRouter(ROUTE_PATHS.GeneralSettings, cId) + "?tab=advanced"
                        break;
                    case ZohoRedirectUrl.AdminEditSite:
                        url = ROUTE_PATHS.ClientSite + `?clientId=${cId}&tab=Integration`;
                        break;
                    case ZohoRedirectUrl.AdminEditSiteShowModal:
                        url = ROUTE_PATHS.ClientSite + `?clientId=${cId}&tab=Integration&showModal=zoho`;
                        break;
                    default:
                        break;
                }
                navigate(url);
            }
        } catch (error) {
            console.log('error', error);
        }
        setLoading(false);
    };
    useEffect(() => {
        getClientAccess()
    }, [])

    return (
        <div className="general-settings-page page-content">
            <div className="zoho-return">
                {loading && <Spin
                    className="spin"
                    indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                ></Spin>}

            </div>
        </div>
    );
}

export default ZohoReturnPage;
