import { AxiosResponse } from "axios";
import {
  GetNotificationParams,
  GetNotificationResponse,
  GetNotificationSettingsParams,
  NotificationSettings,
  RegisterTokenModel,
  SaveClientsSettings,
} from "../models/notification.model";
import { getAsync, postAsync, putAsync } from "./http-client";

const baseUrl = `${process.env.REACT_APP_API_ENPOINT}/notification/api/v1`;

function registerToken(model: RegisterTokenModel) {
  const url = `${baseUrl}/notification/register-token`;
  return postAsync(url, model);
}

function getNotifications(params?: GetNotificationParams): Promise<AxiosResponse<GetNotificationResponse, any>> {
  const url = `${baseUrl}/notification`;
  return getAsync(url, params);
}

function readNotification(id: number) {
  const url = `${baseUrl}/notification/read/${id}`;
  return getAsync(url);
}

function readAllNotification() {
  const url = `${baseUrl}/notification/read-all`;
  return getAsync(url);
}

function clearAllNotification() {
  const url = `${baseUrl}/notification/clear-all`;
  return getAsync(url);
}

function getTeamNotificationSettings(params: GetNotificationSettingsParams) {
  const url = `${baseUrl}/notificationsetting/team`;
  return getAsync(url, params);
}

function saveTeamNotificationSettings(clientId: number, model: NotificationSettings): Promise<AxiosResponse<NotificationSettings>> {
  const url = `${baseUrl}/notificationsetting/team?clientId=${clientId}`;
  return putAsync(url, model);
}

function getMyNotificationSettings(params: GetNotificationSettingsParams) {
  const url = `${baseUrl}/notificationsetting/me`;
  return getAsync(url, params);
}

function saveMyNotificationSettings(clientId: number, isAdmin: boolean, model: NotificationSettings): Promise<AxiosResponse<NotificationSettings>> {
  const url = `${baseUrl}/notificationsetting/me?clientId=${clientId}&isAdmin=${isAdmin}`;
  return putAsync(url, model);
}

function saveClientsSettings(model: SaveClientsSettings) {
  const url = `${baseUrl}/notificationsetting/bulk-update`;
  return putAsync(url, model);
}


export const NotificationApi = {
  registerToken,
  getNotifications,
  readNotification,
  readAllNotification,
  clearAllNotification,
  getTeamNotificationSettings,
  saveTeamNotificationSettings,
  getMyNotificationSettings,
  saveMyNotificationSettings,
  saveClientsSettings,
};
