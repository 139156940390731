import { ApexOptions } from "apexcharts";
import { memo, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { EMPTY_DATA_COLOR } from "../../../../../constants/app-constants";
import { dashboardGaGeneralState } from "../../../../../states/dashboard";
import SpinDiv from "../../../../common/spin-div";
import "./index.scss";
import Volatility from "../../../../common/volatility";

interface Props {
  chartId: string;
}

function VisitorChart(props: Props) {
  const { t } = useTranslation();
  const { chartId } = props;
  const gaGeneralState = useRecoilValue(dashboardGaGeneralState);
  const gaGeneral = gaGeneralState.data;
  const [isEmpy, setIsEmpty] = useState(false);

  useEffect(() => {
    setIsEmpty(!gaGeneral || !gaGeneral.rows || !gaGeneral.rows.length);
  }, [gaGeneral]);

  const fakeData = [
    {
      label: "Returning Visitors",
      color: "#9bc13c",
      value:
        gaGeneral && gaGeneral?.total
          ? gaGeneral.total.users - gaGeneral.total.newUsers
          : 0,
    },
    {
      label: "New Visitors",
      color: "#0185de",
      value: gaGeneral && gaGeneral?.total ? gaGeneral.total.newUsers : 0,
    },
  ];

  const series = fakeData.map((x) => x.value);

  const options: ApexOptions = {
    chart: {
      id: chartId,
      toolbar: {
        show: false,
      },
    },
    labels: fakeData.map((x) => x.label),
    legend: {
      show: false,
    },
    plotOptions: {
      pie: {
        dataLabels: {
          offset: -30,
        },
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "14px",
        fontWeight: "300",
      },
    },
    xaxis: {
      categories: fakeData.map((x) => x.label),
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
      max: 100,
    },
    fill: {
      colors: fakeData.map((x) => x.color),
    },
    tooltip: {
      fillSeriesColor: false,
      custom: function ({ series, seriesIndex }) {
        return (
          '<div class="custom-tooltip-wrapper">' +
          `<span class="tooltip-color" style="background-color: ${fakeData[seriesIndex].color}"></span>` +
          `<span class="tooltip-name">${fakeData[seriesIndex].label}:</span>` +
          "<span>" +
          series[seriesIndex] +
          "</span>" +
          "</div>"
        );
      },
    },
    grid: {
      show: false,
      padding: {
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
      },
    },
  };

  return (
    <div className="visitor-chart">
      <SpinDiv loading={gaGeneralState.loading} style={{ height: 222 }}>
        <>
          <div className="visitor-chart__sum">
            <div>
              <span>{t("dashboard.returnVisitors")}</span>
              <span>
                {gaGeneral && gaGeneral.total
                  ? gaGeneral.total.users - gaGeneral.total.newUsers
                  : 0}
              </span>
              <Volatility value={gaGeneral?.volatility?.users || 0} />
            </div>

            <div>
              <span>{t("dashboard.newVisitor")}</span>
              <span>
                {gaGeneral && gaGeneral.total ? gaGeneral.total.newUsers : 0}
              </span>
              <Volatility value={gaGeneral?.volatility?.newUsers || 0} />
            </div>
          </div>
          <div className="visitor-chart__chartWrapper">
            {isEmpy ? (
              <div
                className="visitor-chart__emptyChart"
                style={{
                  background: EMPTY_DATA_COLOR,
                  width: 222,
                  height: 222,
                }}
              ></div>
            ) : (
              <Chart
                options={options}
                series={series}
                type="pie"
                width={232}
                height={232}
              />
            )}
          </div>
          <div className="visitor-chart__legend">
            {fakeData &&
              fakeData.map((item, index) => (
                <div key={index} className="visitor-chart__legend__item">
                  <div style={{ backgroundColor: item.color }}></div>
                  <span className="visitor-chart__legend__item__name">
                    {item.label}
                  </span>
                </div>
              ))}
          </div>
        </>
      </SpinDiv>
    </div>
  );
}

export default memo(VisitorChart);
